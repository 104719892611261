._1cjeq7g0 {
  flex: 1;
  display: flex;
  justify-content: center;
  width: var(--_1ivttc5fq);
  background-color: var(--_6q1did15);
  color: var(--_6q1didw);
  padding-top: 80px;
  padding-bottom: 60px;
}
._1cjeq7g1 {
  width: var(--_1ivttc5dg);
  padding-left: var(--_1ivttc5cq);
  padding-right: var(--_1ivttc5cq);
}
._1cjeq7g2 {
  margin-left: 15px;
}
._1cjeq7g0 a {
  color: var(--rcioba0);
}
@media screen and (min-width:64em) {
  ._1cjeq7g0 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  ._1cjeq7g1 {
    padding-left: var(--_1ivttc5cr);
    padding-right: var(--_1ivttc5cr);
  }
}