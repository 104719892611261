._12k45md8 {
  --_12k45md1: 80px;
  --_12k45md2: 10;
  --_12k45md4: var(--_12k45md7);
  --_12k45md5: 28px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: var(--_12k45md2);
  transition: top 500ms ease, background-color 500ms ease, color 500ms ease, box-shadow 500ms ease;
}
._12k45md8.with-ribbon {
  --_12k45md4: calc(var(--_12k45md3) + var(--_12k45md7));
}
._12k45md9 {
  background-color: var(--_6q1didw);
  color: var(--_6q1didx);
  box-shadow: inset 0px -1px 0px 0px var(--_6q1did10);
}
._12k45mda {
  background-color: var(--_6q1didu);
  color: var(--_6q1didw);
}
._12k45mdb {
  background-color: var(--_6q1didv);
  color: var(--_6q1didx);
}
._12k45mdc {
  background-color: var(--_6q1didu);
  color: var(--_6q1didx);
  position: absolute;
  z-index: 10;
}
._12k45mdd {
  position: fixed;
  top: 0;
}
._12k45mde {
  background-color: #fff;
  width: 100%;
  height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow: inset 0px -1px 0px 0px var(--_6q1did10);
}
._12k45mdf {
  display: none;
}
._12k45md8 > nav:first-of-type, ._12k45mde > nav:first-of-type {
  margin-left: 7.407407407407407vw;
}
._12k45md8 > nav:last-of-type, ._12k45mde > nav:last-of-type {
  margin-right: 7.407407407407407vw;
}
._12k45md8 > nav > ul, ._12k45mde > nav > ul {
  list-style: none;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: var(--_12k45md0);
}
._12k45md8 > nav > ul > li > a, ._12k45mde > nav > ul > li > a {
  color: inherit;
}
._12k45md8 > nav#center > ul, ._12k45md8 > div > nav#center > ul {
  gap: 0;
}
._12k45md8 > nav#center > ul > li, ._12k45md8 > div > nav#center > ul > li {
  padding-left: 20px;
  padding-right: 20px;
}
._12k45md8 nav > ul > li > button, ._12k45md8 nav > ul > li > span, ._12k45md8 nav > ul > li > a, ._12k45md8 nav > ul > li > a > span {
  padding: 0;
  font-weight: var(--_1ivttc5hn);
  font-size: var(--_1ivttc5h8);
  border-radius: 0;
}
._12k45md8 nav#right {
  --_12k45md0: 3.7037037037037037vw;
}
._12k45md8 .collapse-on-mobile {
  display: none;
}
._12k45md8 > nav > ul > li > ._964fy0, ._12k45mde > nav > ul > li > ._964fy0 {
  padding: 0;
  width: auto;
}
._12k45md8 > nav > ul > li ._15gj9s20, ._12k45mde > nav > ul > li ._15gj9s20 {
  margin: 0;
  line-height: 1;
}
._12k45md8 ._1bndnd21 {
  top: var(--_12k45md4);
}
._12k45md8 ._1bndnd21 ._1bndnd21 {
  top: 0;
}
._12k45mdg {
  position: absolute;
  top: 0;
  left: 0;
  border-bottom-right-radius: 8px;
  background-color: var(--_6q1did1j);
  padding: 8px;
  z-index: 11;
  opacity: 0;
  transform: translate3d(0, -101%, 0);
  transition: transform .5s ease, opacity .5s ease;
}
._12k45mdg:focus {
  transform: translateZ(0);
  opacity: 1;
}
._12k45mdh {
  --_12k45md6: inherit;
  display: flex;
  align-items: center;
  height: var(--_12k45md1);
}
._12k45md9 ._12k45mdh {
  color: var(--_12k45md6);
}
._12k45mde > nav:last-of-type > ul > li > span, ._12k45mde > nav:last-of-type > ul > li > a, #sonospro > nav:last-of-type > ul > li > span, #sonospro > nav:last-of-type > ul > li > a {
  border-color: currentColor;
  border-style: solid;
  border-radius: 80px;
  text-align: center;
  border-width: var(--_1ivttc5i3);
  padding: 8px 24px;
}
._12k45mde > nav:last-of-type > ul > li.base > span, ._12k45mde > nav:last-of-type > ul > li.base > a, #sonospro > nav:last-of-type > ul > li.base > span, #sonospro > nav:last-of-type > ul > li.base > a {
  border-color: var(--_6q1didx);
  background-color: var(--_6q1didx);
}
#sonospro > nav > ul > li {
  height: 56px;
}
#sonospro {
  height: 120px;
  flex-wrap: wrap;
}
._12k45md8#sonospro .collapse-on-mobile {
  display: block;
}

._12k45md8 #sonospro nav#right > ul > li:last-child,
#sonospro nav#right > ul > li:last-child
 {
  display: flex;
}
#sonospro > nav#right {
  flex-basis: 100%;
  text-align: center;
  margin-right: 0;
}
#sonospro > nav#center > ul > li > span, #sonospro > nav#center > ul > li > a {
  margin-right: 24px;
}
#sonospro > nav#center > ul > li, #sonospro > div > nav#center > ul > li {
  padding-left: 0;
  padding-right: 0;
}
#sonospro > nav:last-of-type > ul > li > span, #sonospro > nav:last-of-type > ul > li > a {
  width: 152px;
  margin: 0 8px;
}
nav#center > ul li._12k45mdh._12k45mdi {
  border-bottom: 3px solid transparent;
}
nav#center > ul li._12k45mdh._12k45mdi:hover {
  border-color: var(--_6q1did15);
}
#sonospro li[data-testid="nav-item-get-started"] a {
  background-color: var(--_6q1didx);
  color: var(--_6q1didw);
}
._12k45mdj {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
._12k45mdk {
  display: block;
  font-size: var(--_1ivttc5h8);
  line-height: var(--_1ivttc5hs);
  font-weight: var(--_1ivttc5hn);
  margin-bottom: 20px;
}
@media screen and (min-width:64em) {
  ._12k45md8 {
    --_12k45md0: 40px;
    --_12k45md1: 100px;
  }
  ._12k45md8 > nav:first-of-type, ._12k45mde > nav:first-of-type {
    margin-left: 3.7037037037037037vw;
  }
  ._12k45md8 > nav:last-of-type, ._12k45mde > nav:last-of-type {
    margin-right: 3.7037037037037037vw;
  }
  ._12k45md8 nav#right {
    --_12k45md0: 24px;
  }
  ._12k45md8 nav#right > ul > li:last-child {
    display: none;
  }
  ._12k45md8 .collapse-on-mobile {
    display: block;
  }
  ._12k45md8:not(.with-search):not(.with-myAccount) ._1bndnd21 {
    top: calc(var(--_12k45md4) + var(--_12k45md1));
  }
  #sonospro {
    height: 100px;
    flex-wrap: nowrap;
  }
  ._12k45mde#sonospro {
    height: 64px;
    flex-wrap: nowrap;
  }
  #sonospro > nav#right {
    flex-basis: auto;
    text-align: right;
    margin-right: 3.7037037037037037vw;
  }
  #sonospro > nav#center > ul > li > span, #sonospro > nav#center > ul > li > a {
    margin-right: 0;
  }
  #sonospro > nav#center > ul > li, #sonospro > div > nav#center > ul > li {
    padding-left: 20px;
    padding-right: 20px;
  }
  #sonospro > nav:last-of-type > ul > li > span, #sonospro > nav:last-of-type > ul > li > a {
    width: auto;
    margin: 0;
  }
  ._12k45mdk {
    font-size: var(--_1ivttc5ha);
  }
}