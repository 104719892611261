._964fy0 {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-style: solid;
  border-radius: var(--_1ivttc5e);
  text-align: center;
  border-width: var(--_1ivttc5i3);
  padding: var(--_1ivttc55) var(--_1ivttc57);
  font-weight: var(--_1ivttc5hn);
  line-height: var(--_1ivttc5hs);
  font-size: var(--_1ivttc5h8);
  transition: opacity 100ms ease-in;
  width: 100%;
  color: inherit;
}
._964fy0:hover {
  opacity: 0.7;
}
._964fy0:active {
  opacity: 0.5;
}
._964fy0:disabled {
  cursor: not-allowed;
  background-color: var(--_6q1did10);
  color: var(--_6q1did14);
  border-color: var(--_6q1did10);
}
._964fy0:hover:disabled {
  opacity: 1;
}
._964fy0:active:disabled {
  opacity: 1;
}
._964fy1 {
  border-color: var(--_6q1didx);
  background-color: var(--_6q1didx);
  color: var(--_6q1didw);
}
._964fy2 {
  border-color: currentColor;
  background-color: var(--_6q1didv);
  color: currentColor;
}
._964fy3 {
  border-color: transparent;
  background-color: var(--_6q1didv);
  color: currentColor;
}
._964fy4 {
  display: inline-flex;
  width: var(--_1ivttc52);
}
._964fy0 > * {
  margin-left: var(--_1ivttc55);
  margin-right: var(--_1ivttc55);
}
@media screen and (min-width:64em) {
  ._964fy0 {
    border-width: var(--_1ivttc5i4);
    padding: var(--_1ivttc56) var(--_1ivttc5a);
    font-weight: var(--_1ivttc5ho);
    font-size: var(--_1ivttc5h9);
  }
}