._13582ja1 {
  text-align: center;
  padding-left: var(--_1ivttc5cr);
  padding-right: var(--_1ivttc5cr);
  line-height: var(--_1ivttc5hr);
  font-weight: var(--_1ivttc5ho);
  margin-bottom: var(--_1ivttc58);
  font-size: var(--_1ivttc5hc);
}
._13582ja2 {
  padding-bottom: var(--_1ivttc53e);
  padding-left: var(--_1ivttc5cr);
}
._13582ja3 {
  width: var(--_1ivttc5cz);
}
._13582ja4 {
  width: var(--_1ivttc5d0);
}
._13582ja5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: var(--_13582ja0);
}
._13582ja6 {
  font-size: var(--_1ivttc5h9);
  font-weight: var(--_1ivttc5ho);
  text-align: center;
  padding-top: var(--_1ivttc56);
}
._13582ja7 {
  font-size: var(--_1ivttc5h7);
  font-weight: var(--_1ivttc5hn);
  text-align: center;
}
._13582ja8 {
  width: var(--_13582ja0)!important;
  height: var(--_13582ja0)!important;
  padding: 8px !important;
}
@media screen and (min-width:64em) {
  ._13582ja1 {
    margin-bottom: var(--_1ivttc5f);
    font-size: var(--_1ivttc5hh);
  }
  ._13582ja4 {
    width: var(--_1ivttc5cz);
  }
}