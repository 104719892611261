._1untng61 {
  top: 0;
  height: 100%;
}
._1untng62 {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  padding-bottom: 16px;
}
._1untng63 {
  width: 100%;
  overflow: auto;
}
.with-ribbon ._1untng63 {
  height: calc((100vh - var(--_12k45md1)) - var(--_12k45md3));
}
._1untng64 {
  align-items: flex-start;
}
._1untng66 {
  overflow: initial;
}
._1untng61 > .v6jvm57 > .v6jvm52 {
  overflow-y: auto;
  overscroll-behavior: contain;
}
._1untng68 {
  justify-content: space-between;
}
._1untng68 > .vvbgwx2 {
  position: static;
}
._1untng64 ._1untng68 ._1untng65 {
  display: none;
}
._1untng64 ._1untng68 {
  display: flex;
}
._1untng64 ._12k45mdh {
  height: 60px;
}
._1untng64 ._12k45mdh > a {
  font-size: var(--_1ivttc5ha);
}
._1untng61._1untng67 ._1untng66 {
  overflow-y: unset;
}
@media screen and (min-width:48em) {
  ._1untng61 {
    height: auto;
  }
}
@media screen and (min-width:64em) {
  ._1untng61 {
    height: auto;
    min-height: 200px;
    transform-origin: top center;
  }
  ._1untng62 {
    justify-content: center;
    flex-direction: var(--_1untng60);
    overflow: hidden;
  }
  .with-ribbon ._1untng63 {
    height: auto;
  }
  ._1untng64 {
    width: 25%;
    left: unset;
    right: 0;
    height: 100vh;
    min-width: 352px;
  }
  ._1untng65 {
    display: none;
  }
  ._1untng68 {
    display: none;
    box-shadow: none;
  }
}