:root {
  --_1ivttc50: 'aktiv-grotesk', Helvetica, Arial, sans-serif;
  --_1ivttc51: auto;
  --_1ivttc52: fit-content;
  --_1ivttc53: 100%;
  --_1ivttc54: 0px;
  --_1ivttc55: 8px;
  --_1ivttc56: 16px;
  --_1ivttc57: 24px;
  --_1ivttc58: 32px;
  --_1ivttc59: 40px;
  --_1ivttc5a: 48px;
  --_1ivttc5b: 56px;
  --_1ivttc5c: 64px;
  --_1ivttc5d: 72px;
  --_1ivttc5e: 80px;
  --_1ivttc5f: 88px;
  --_1ivttc5g: 96px;
  --_1ivttc5h: 104px;
  --_1ivttc5i: 112px;
  --_1ivttc5j: 120px;
  --_1ivttc5k: 128px;
  --_1ivttc5l: 136px;
  --_1ivttc5m: 144px;
  --_1ivttc5n: 152px;
  --_1ivttc5o: 160px;
  --_1ivttc5p: 168px;
  --_1ivttc5q: 176px;
  --_1ivttc5r: 184px;
  --_1ivttc5s: 192px;
  --_1ivttc5t: 200px;
  --_1ivttc5u: 208px;
  --_1ivttc5v: 216px;
  --_1ivttc5w: 224px;
  --_1ivttc5x: 232px;
  --_1ivttc5y: 240px;
  --_1ivttc5z: 248px;
  --_1ivttc510: 256px;
  --_1ivttc511: 264px;
  --_1ivttc512: 272px;
  --_1ivttc513: 280px;
  --_1ivttc514: 288px;
  --_1ivttc515: 296px;
  --_1ivttc516: 304px;
  --_1ivttc517: 312px;
  --_1ivttc518: 320px;
  --_1ivttc519: 328px;
  --_1ivttc51a: 336px;
  --_1ivttc51b: 344px;
  --_1ivttc51c: 352px;
  --_1ivttc51d: 360px;
  --_1ivttc51e: 368px;
  --_1ivttc51f: 376px;
  --_1ivttc51g: 384px;
  --_1ivttc51h: 392px;
  --_1ivttc51i: 400px;
  --_1ivttc51j: 408px;
  --_1ivttc51k: 416px;
  --_1ivttc51l: 424px;
  --_1ivttc51m: 432px;
  --_1ivttc51n: 440px;
  --_1ivttc51o: 448px;
  --_1ivttc51p: 456px;
  --_1ivttc51q: 464px;
  --_1ivttc51r: 472px;
  --_1ivttc51s: 480px;
  --_1ivttc51t: 488px;
  --_1ivttc51u: 496px;
  --_1ivttc51v: 504px;
  --_1ivttc51w: 512px;
  --_1ivttc51x: 520px;
  --_1ivttc51y: 528px;
  --_1ivttc51z: 536px;
  --_1ivttc520: 544px;
  --_1ivttc521: 552px;
  --_1ivttc522: 560px;
  --_1ivttc523: 568px;
  --_1ivttc524: 576px;
  --_1ivttc525: 584px;
  --_1ivttc526: 592px;
  --_1ivttc527: 600px;
  --_1ivttc528: 608px;
  --_1ivttc529: 616px;
  --_1ivttc52a: 624px;
  --_1ivttc52b: 632px;
  --_1ivttc52c: 640px;
  --_1ivttc52d: 648px;
  --_1ivttc52e: 656px;
  --_1ivttc52f: 664px;
  --_1ivttc52g: 672px;
  --_1ivttc52h: 680px;
  --_1ivttc52i: 688px;
  --_1ivttc52j: 696px;
  --_1ivttc52k: 704px;
  --_1ivttc52l: 712px;
  --_1ivttc52m: 720px;
  --_1ivttc52n: 728px;
  --_1ivttc52o: 736px;
  --_1ivttc52p: 744px;
  --_1ivttc52q: 752px;
  --_1ivttc52r: 760px;
  --_1ivttc52s: 768px;
  --_1ivttc52t: 776px;
  --_1ivttc52u: 784px;
  --_1ivttc52v: 792px;
  --_1ivttc52w: 800px;
  --_1ivttc52x: 808px;
  --_1ivttc52y: 816px;
  --_1ivttc52z: 824px;
  --_1ivttc530: 832px;
  --_1ivttc531: 840px;
  --_1ivttc532: 848px;
  --_1ivttc533: 856px;
  --_1ivttc534: 864px;
  --_1ivttc535: 872px;
  --_1ivttc536: 880px;
  --_1ivttc537: 888px;
  --_1ivttc538: 896px;
  --_1ivttc539: 904px;
  --_1ivttc53a: 4px;
  --_1ivttc53b: 12px;
  --_1ivttc53c: 20px;
  --_1ivttc53d: 28px;
  --_1ivttc53e: 36px;
  --_1ivttc53f: 44px;
  --_1ivttc53g: 52px;
  --_1ivttc53h: 60px;
  --_1ivttc53i: 68px;
  --_1ivttc53j: 76px;
  --_1ivttc53k: 84px;
  --_1ivttc53l: 92px;
  --_1ivttc53m: 100px;
  --_1ivttc53n: 108px;
  --_1ivttc53o: 116px;
  --_1ivttc53p: 124px;
  --_1ivttc53q: 132px;
  --_1ivttc53r: 140px;
  --_1ivttc53s: 148px;
  --_1ivttc53t: 156px;
  --_1ivttc53u: 164px;
  --_1ivttc53v: 172px;
  --_1ivttc53w: 180px;
  --_1ivttc53x: 188px;
  --_1ivttc53y: 196px;
  --_1ivttc53z: 204px;
  --_1ivttc540: 212px;
  --_1ivttc541: 220px;
  --_1ivttc542: 228px;
  --_1ivttc543: 236px;
  --_1ivttc544: 244px;
  --_1ivttc545: 252px;
  --_1ivttc546: 260px;
  --_1ivttc547: 268px;
  --_1ivttc548: 276px;
  --_1ivttc549: 284px;
  --_1ivttc54a: 292px;
  --_1ivttc54b: 300px;
  --_1ivttc54c: 308px;
  --_1ivttc54d: 316px;
  --_1ivttc54e: 324px;
  --_1ivttc54f: 332px;
  --_1ivttc54g: 340px;
  --_1ivttc54h: 348px;
  --_1ivttc54i: 356px;
  --_1ivttc54j: 364px;
  --_1ivttc54k: 372px;
  --_1ivttc54l: 380px;
  --_1ivttc54m: 388px;
  --_1ivttc54n: 396px;
  --_1ivttc54o: 404px;
  --_1ivttc54p: 412px;
  --_1ivttc54q: 420px;
  --_1ivttc54r: 428px;
  --_1ivttc54s: 436px;
  --_1ivttc54t: 444px;
  --_1ivttc54u: 452px;
  --_1ivttc54v: 460px;
  --_1ivttc54w: 468px;
  --_1ivttc54x: 476px;
  --_1ivttc54y: 484px;
  --_1ivttc54z: 492px;
  --_1ivttc550: 500px;
  --_1ivttc551: 508px;
  --_1ivttc552: 516px;
  --_1ivttc553: 524px;
  --_1ivttc554: 532px;
  --_1ivttc555: 540px;
  --_1ivttc556: 548px;
  --_1ivttc557: 556px;
  --_1ivttc558: 564px;
  --_1ivttc559: 572px;
  --_1ivttc55a: 580px;
  --_1ivttc55b: 588px;
  --_1ivttc55c: 596px;
  --_1ivttc55d: 604px;
  --_1ivttc55e: 612px;
  --_1ivttc55f: 620px;
  --_1ivttc55g: 628px;
  --_1ivttc55h: 636px;
  --_1ivttc55i: 644px;
  --_1ivttc55j: 652px;
  --_1ivttc55k: 660px;
  --_1ivttc55l: 668px;
  --_1ivttc55m: 676px;
  --_1ivttc55n: 684px;
  --_1ivttc55o: 692px;
  --_1ivttc55p: 700px;
  --_1ivttc55q: 708px;
  --_1ivttc55r: 716px;
  --_1ivttc55s: 724px;
  --_1ivttc55t: 732px;
  --_1ivttc55u: 740px;
  --_1ivttc55v: 748px;
  --_1ivttc55w: 756px;
  --_1ivttc55x: 764px;
  --_1ivttc55y: 772px;
  --_1ivttc55z: 780px;
  --_1ivttc560: 788px;
  --_1ivttc561: 796px;
  --_1ivttc562: 804px;
  --_1ivttc563: 812px;
  --_1ivttc564: 820px;
  --_1ivttc565: 828px;
  --_1ivttc566: 836px;
  --_1ivttc567: 844px;
  --_1ivttc568: 852px;
  --_1ivttc569: 860px;
  --_1ivttc56a: 868px;
  --_1ivttc56b: 876px;
  --_1ivttc56c: 884px;
  --_1ivttc56d: 892px;
  --_1ivttc56e: 900px;
  --_1ivttc56f: -8px;
  --_1ivttc56g: -16px;
  --_1ivttc56h: -24px;
  --_1ivttc56i: -32px;
  --_1ivttc56j: -40px;
  --_1ivttc56k: -48px;
  --_1ivttc56l: -56px;
  --_1ivttc56m: -64px;
  --_1ivttc56n: -72px;
  --_1ivttc56o: -80px;
  --_1ivttc56p: -88px;
  --_1ivttc56q: -96px;
  --_1ivttc56r: -104px;
  --_1ivttc56s: -112px;
  --_1ivttc56t: -120px;
  --_1ivttc56u: -128px;
  --_1ivttc56v: -136px;
  --_1ivttc56w: -144px;
  --_1ivttc56x: -152px;
  --_1ivttc56y: -160px;
  --_1ivttc56z: -168px;
  --_1ivttc570: -176px;
  --_1ivttc571: -184px;
  --_1ivttc572: -192px;
  --_1ivttc573: -200px;
  --_1ivttc574: -208px;
  --_1ivttc575: -216px;
  --_1ivttc576: -224px;
  --_1ivttc577: -232px;
  --_1ivttc578: -240px;
  --_1ivttc579: -248px;
  --_1ivttc57a: -256px;
  --_1ivttc57b: -264px;
  --_1ivttc57c: -272px;
  --_1ivttc57d: -280px;
  --_1ivttc57e: -288px;
  --_1ivttc57f: -296px;
  --_1ivttc57g: -304px;
  --_1ivttc57h: -312px;
  --_1ivttc57i: -320px;
  --_1ivttc57j: -328px;
  --_1ivttc57k: -336px;
  --_1ivttc57l: -344px;
  --_1ivttc57m: -352px;
  --_1ivttc57n: -360px;
  --_1ivttc57o: -368px;
  --_1ivttc57p: -376px;
  --_1ivttc57q: -384px;
  --_1ivttc57r: -392px;
  --_1ivttc57s: -400px;
  --_1ivttc57t: -408px;
  --_1ivttc57u: -416px;
  --_1ivttc57v: -424px;
  --_1ivttc57w: -432px;
  --_1ivttc57x: -440px;
  --_1ivttc57y: -448px;
  --_1ivttc57z: -456px;
  --_1ivttc580: -464px;
  --_1ivttc581: -472px;
  --_1ivttc582: -480px;
  --_1ivttc583: -488px;
  --_1ivttc584: -496px;
  --_1ivttc585: -504px;
  --_1ivttc586: -512px;
  --_1ivttc587: -520px;
  --_1ivttc588: -528px;
  --_1ivttc589: -536px;
  --_1ivttc58a: -544px;
  --_1ivttc58b: -552px;
  --_1ivttc58c: -560px;
  --_1ivttc58d: -568px;
  --_1ivttc58e: -576px;
  --_1ivttc58f: -584px;
  --_1ivttc58g: -592px;
  --_1ivttc58h: -600px;
  --_1ivttc58i: -608px;
  --_1ivttc58j: -616px;
  --_1ivttc58k: -624px;
  --_1ivttc58l: -632px;
  --_1ivttc58m: -640px;
  --_1ivttc58n: -648px;
  --_1ivttc58o: -656px;
  --_1ivttc58p: -664px;
  --_1ivttc58q: -672px;
  --_1ivttc58r: -680px;
  --_1ivttc58s: -688px;
  --_1ivttc58t: -696px;
  --_1ivttc58u: -704px;
  --_1ivttc58v: -712px;
  --_1ivttc58w: -720px;
  --_1ivttc58x: -728px;
  --_1ivttc58y: -736px;
  --_1ivttc58z: -744px;
  --_1ivttc590: -752px;
  --_1ivttc591: -760px;
  --_1ivttc592: -768px;
  --_1ivttc593: -776px;
  --_1ivttc594: -784px;
  --_1ivttc595: -792px;
  --_1ivttc596: -800px;
  --_1ivttc597: -808px;
  --_1ivttc598: -816px;
  --_1ivttc599: -824px;
  --_1ivttc59a: -832px;
  --_1ivttc59b: -840px;
  --_1ivttc59c: -848px;
  --_1ivttc59d: -856px;
  --_1ivttc59e: -864px;
  --_1ivttc59f: -872px;
  --_1ivttc59g: -880px;
  --_1ivttc59h: -888px;
  --_1ivttc59i: -896px;
  --_1ivttc59j: -904px;
  --_1ivttc59k: -4px;
  --_1ivttc59l: -12px;
  --_1ivttc59m: -20px;
  --_1ivttc59n: -28px;
  --_1ivttc59o: -36px;
  --_1ivttc59p: -44px;
  --_1ivttc59q: -52px;
  --_1ivttc59r: -60px;
  --_1ivttc59s: -68px;
  --_1ivttc59t: -76px;
  --_1ivttc59u: -84px;
  --_1ivttc59v: -92px;
  --_1ivttc59w: -100px;
  --_1ivttc59x: -108px;
  --_1ivttc59y: -116px;
  --_1ivttc59z: -124px;
  --_1ivttc5a0: -132px;
  --_1ivttc5a1: -140px;
  --_1ivttc5a2: -148px;
  --_1ivttc5a3: -156px;
  --_1ivttc5a4: -164px;
  --_1ivttc5a5: -172px;
  --_1ivttc5a6: -180px;
  --_1ivttc5a7: -188px;
  --_1ivttc5a8: -196px;
  --_1ivttc5a9: -204px;
  --_1ivttc5aa: -212px;
  --_1ivttc5ab: -220px;
  --_1ivttc5ac: -228px;
  --_1ivttc5ad: -236px;
  --_1ivttc5ae: -244px;
  --_1ivttc5af: -252px;
  --_1ivttc5ag: -260px;
  --_1ivttc5ah: -268px;
  --_1ivttc5ai: -276px;
  --_1ivttc5aj: -284px;
  --_1ivttc5ak: -292px;
  --_1ivttc5al: -300px;
  --_1ivttc5am: -308px;
  --_1ivttc5an: -316px;
  --_1ivttc5ao: -324px;
  --_1ivttc5ap: -332px;
  --_1ivttc5aq: -340px;
  --_1ivttc5ar: -348px;
  --_1ivttc5as: -356px;
  --_1ivttc5at: -364px;
  --_1ivttc5au: -372px;
  --_1ivttc5av: -380px;
  --_1ivttc5aw: -388px;
  --_1ivttc5ax: -396px;
  --_1ivttc5ay: -404px;
  --_1ivttc5az: -412px;
  --_1ivttc5b0: -420px;
  --_1ivttc5b1: -428px;
  --_1ivttc5b2: -436px;
  --_1ivttc5b3: -444px;
  --_1ivttc5b4: -452px;
  --_1ivttc5b5: -460px;
  --_1ivttc5b6: -468px;
  --_1ivttc5b7: -476px;
  --_1ivttc5b8: -484px;
  --_1ivttc5b9: -492px;
  --_1ivttc5ba: -500px;
  --_1ivttc5bb: -508px;
  --_1ivttc5bc: -516px;
  --_1ivttc5bd: -524px;
  --_1ivttc5be: -532px;
  --_1ivttc5bf: -540px;
  --_1ivttc5bg: -548px;
  --_1ivttc5bh: -556px;
  --_1ivttc5bi: -564px;
  --_1ivttc5bj: -572px;
  --_1ivttc5bk: -580px;
  --_1ivttc5bl: -588px;
  --_1ivttc5bm: -596px;
  --_1ivttc5bn: -604px;
  --_1ivttc5bo: -612px;
  --_1ivttc5bp: -620px;
  --_1ivttc5bq: -628px;
  --_1ivttc5br: -636px;
  --_1ivttc5bs: -644px;
  --_1ivttc5bt: -652px;
  --_1ivttc5bu: -660px;
  --_1ivttc5bv: -668px;
  --_1ivttc5bw: -676px;
  --_1ivttc5bx: -684px;
  --_1ivttc5by: -692px;
  --_1ivttc5bz: -700px;
  --_1ivttc5c0: -708px;
  --_1ivttc5c1: -716px;
  --_1ivttc5c2: -724px;
  --_1ivttc5c3: -732px;
  --_1ivttc5c4: -740px;
  --_1ivttc5c5: -748px;
  --_1ivttc5c6: -756px;
  --_1ivttc5c7: -764px;
  --_1ivttc5c8: -772px;
  --_1ivttc5c9: -780px;
  --_1ivttc5ca: -788px;
  --_1ivttc5cb: -796px;
  --_1ivttc5cc: -804px;
  --_1ivttc5cd: -812px;
  --_1ivttc5ce: -820px;
  --_1ivttc5cf: -828px;
  --_1ivttc5cg: -836px;
  --_1ivttc5ch: -844px;
  --_1ivttc5ci: -852px;
  --_1ivttc5cj: -860px;
  --_1ivttc5ck: -868px;
  --_1ivttc5cl: -876px;
  --_1ivttc5cm: -884px;
  --_1ivttc5cn: -892px;
  --_1ivttc5co: -900px;
  --_1ivttc5cp: 0vw;
  --_1ivttc5cq: 3.7037037037037037vw;
  --_1ivttc5cr: 7.407407407407407vw;
  --_1ivttc5cs: 11.11111111111111vw;
  --_1ivttc5ct: 14.814814814814815vw;
  --_1ivttc5cu: 18.51851851851852vw;
  --_1ivttc5cv: 22.22222222222222vw;
  --_1ivttc5cw: 25.925925925925927vw;
  --_1ivttc5cx: 29.62962962962963vw;
  --_1ivttc5cy: 33.333333333333336vw;
  --_1ivttc5cz: 37.03703703703704vw;
  --_1ivttc5d0: 40.74074074074074vw;
  --_1ivttc5d1: 44.44444444444444vw;
  --_1ivttc5d2: 48.148148148148145vw;
  --_1ivttc5d3: 51.851851851851855vw;
  --_1ivttc5d4: 55.55555555555556vw;
  --_1ivttc5d5: 59.25925925925926vw;
  --_1ivttc5d6: 62.96296296296296vw;
  --_1ivttc5d7: 66.66666666666667vw;
  --_1ivttc5d8: 70.37037037037037vw;
  --_1ivttc5d9: 74.07407407407408vw;
  --_1ivttc5da: 77.77777777777777vw;
  --_1ivttc5db: 81.48148148148148vw;
  --_1ivttc5dc: 85.18518518518519vw;
  --_1ivttc5dd: 88.88888888888889vw;
  --_1ivttc5de: 92.5925925925926vw;
  --_1ivttc5df: 96.29629629629629vw;
  --_1ivttc5dg: 100vw;
  --_1ivttc5dh: 1.8518518518518519vw;
  --_1ivttc5di: 5.555555555555555vw;
  --_1ivttc5dj: 9.25925925925926vw;
  --_1ivttc5dk: 12.962962962962964vw;
  --_1ivttc5dl: 16.666666666666668vw;
  --_1ivttc5dm: 20.37037037037037vw;
  --_1ivttc5dn: 24.074074074074073vw;
  --_1ivttc5do: 27.77777777777778vw;
  --_1ivttc5dp: 31.48148148148148vw;
  --_1ivttc5dq: 35.18518518518518vw;
  --_1ivttc5dr: 38.888888888888886vw;
  --_1ivttc5ds: 42.592592592592595vw;
  --_1ivttc5dt: 46.2962962962963vw;
  --_1ivttc5du: 50vw;
  --_1ivttc5dv: 53.7037037037037vw;
  --_1ivttc5dw: 57.407407407407405vw;
  --_1ivttc5dx: 61.111111111111114vw;
  --_1ivttc5dy: 64.81481481481481vw;
  --_1ivttc5dz: 68.51851851851852vw;
  --_1ivttc5e0: 72.22222222222223vw;
  --_1ivttc5e1: 75.92592592592592vw;
  --_1ivttc5e2: 79.62962962962963vw;
  --_1ivttc5e3: 83.33333333333333vw;
  --_1ivttc5e4: 87.03703703703704vw;
  --_1ivttc5e5: 90.74074074074075vw;
  --_1ivttc5e6: 94.44444444444444vw;
  --_1ivttc5e7: 98.14814814814815vw;
  --_1ivttc5e8: -3.7037037037037037vw;
  --_1ivttc5e9: -7.407407407407407vw;
  --_1ivttc5ea: -11.11111111111111vw;
  --_1ivttc5eb: -14.814814814814815vw;
  --_1ivttc5ec: -18.51851851851852vw;
  --_1ivttc5ed: -22.22222222222222vw;
  --_1ivttc5ee: -25.925925925925927vw;
  --_1ivttc5ef: -29.62962962962963vw;
  --_1ivttc5eg: -33.333333333333336vw;
  --_1ivttc5eh: -37.03703703703704vw;
  --_1ivttc5ei: -40.74074074074074vw;
  --_1ivttc5ej: -44.44444444444444vw;
  --_1ivttc5ek: -48.148148148148145vw;
  --_1ivttc5el: -51.851851851851855vw;
  --_1ivttc5em: -55.55555555555556vw;
  --_1ivttc5en: -59.25925925925926vw;
  --_1ivttc5eo: -62.96296296296296vw;
  --_1ivttc5ep: -66.66666666666667vw;
  --_1ivttc5eq: -70.37037037037037vw;
  --_1ivttc5er: -74.07407407407408vw;
  --_1ivttc5es: -77.77777777777777vw;
  --_1ivttc5et: -81.48148148148148vw;
  --_1ivttc5eu: -85.18518518518519vw;
  --_1ivttc5ev: -88.88888888888889vw;
  --_1ivttc5ew: -92.5925925925926vw;
  --_1ivttc5ex: -96.29629629629629vw;
  --_1ivttc5ey: -100vw;
  --_1ivttc5ez: -1.8518518518518519vw;
  --_1ivttc5f0: -5.555555555555555vw;
  --_1ivttc5f1: -9.25925925925926vw;
  --_1ivttc5f2: -12.962962962962964vw;
  --_1ivttc5f3: -16.666666666666668vw;
  --_1ivttc5f4: -20.37037037037037vw;
  --_1ivttc5f5: -24.074074074074073vw;
  --_1ivttc5f6: -27.77777777777778vw;
  --_1ivttc5f7: -31.48148148148148vw;
  --_1ivttc5f8: -35.18518518518518vw;
  --_1ivttc5f9: -38.888888888888886vw;
  --_1ivttc5fa: -42.592592592592595vw;
  --_1ivttc5fb: -46.2962962962963vw;
  --_1ivttc5fc: -50vw;
  --_1ivttc5fd: -53.7037037037037vw;
  --_1ivttc5fe: -57.407407407407405vw;
  --_1ivttc5ff: -61.111111111111114vw;
  --_1ivttc5fg: -64.81481481481481vw;
  --_1ivttc5fh: -68.51851851851852vw;
  --_1ivttc5fi: -72.22222222222223vw;
  --_1ivttc5fj: -75.92592592592592vw;
  --_1ivttc5fk: -79.62962962962963vw;
  --_1ivttc5fl: -83.33333333333333vw;
  --_1ivttc5fm: -87.03703703703704vw;
  --_1ivttc5fn: -90.74074074074075vw;
  --_1ivttc5fo: -94.44444444444444vw;
  --_1ivttc5fp: -98.14814814814815vw;
  --_1ivttc5fq: 100vw;
  --_1ivttc5fr: 0px;
  --_1ivttc5fs: 112.5px;
  --_1ivttc5ft: 225px;
  --_1ivttc5fu: 337.5px;
  --_1ivttc5fv: 450px;
  --_1ivttc5fw: 562.5px;
  --_1ivttc5fx: 675px;
  --_1ivttc5fy: 787.5px;
  --_1ivttc5fz: 900px;
  --_1ivttc5g0: 1012.5px;
  --_1ivttc5g1: 1125px;
  --_1ivttc5g2: 1237.5px;
  --_1ivttc5g3: 1350px;
  --_1ivttc5g4: 56.25px;
  --_1ivttc5g5: 168.75px;
  --_1ivttc5g6: 281.25px;
  --_1ivttc5g7: 393.75px;
  --_1ivttc5g8: 506.25px;
  --_1ivttc5g9: 618.75px;
  --_1ivttc5ga: 731.25px;
  --_1ivttc5gb: 843.75px;
  --_1ivttc5gc: 956.25px;
  --_1ivttc5gd: 1068.75px;
  --_1ivttc5ge: 1181.25px;
  --_1ivttc5gf: 1293.75px;
  --_1ivttc5gg: -112.5px;
  --_1ivttc5gh: -225px;
  --_1ivttc5gi: -337.5px;
  --_1ivttc5gj: -450px;
  --_1ivttc5gk: -562.5px;
  --_1ivttc5gl: -675px;
  --_1ivttc5gm: -787.5px;
  --_1ivttc5gn: -900px;
  --_1ivttc5go: -1012.5px;
  --_1ivttc5gp: -1125px;
  --_1ivttc5gq: -1237.5px;
  --_1ivttc5gr: -1350px;
  --_1ivttc5gs: -56.25px;
  --_1ivttc5gt: -168.75px;
  --_1ivttc5gu: -281.25px;
  --_1ivttc5gv: -393.75px;
  --_1ivttc5gw: -506.25px;
  --_1ivttc5gx: -618.75px;
  --_1ivttc5gy: -731.25px;
  --_1ivttc5gz: -843.75px;
  --_1ivttc5h0: -956.25px;
  --_1ivttc5h1: -1068.75px;
  --_1ivttc5h2: -1181.25px;
  --_1ivttc5h3: -1293.75px;
  --_1ivttc5h4: 100vh;
  --_1ivttc5h5: 10px;
  --_1ivttc5h6: 12px;
  --_1ivttc5h7: 14px;
  --_1ivttc5h8: 16px;
  --_1ivttc5h9: 18px;
  --_1ivttc5ha: 21px;
  --_1ivttc5hb: 26px;
  --_1ivttc5hc: 32px;
  --_1ivttc5hd: 40px;
  --_1ivttc5he: 48px;
  --_1ivttc5hf: 54px;
  --_1ivttc5hg: 64px;
  --_1ivttc5hh: 72px;
  --_1ivttc5hi: 96px;
  --_1ivttc5hj: 108px;
  --_1ivttc5hk: 162px;
  --_1ivttc5hl: 243px;
  --_1ivttc5hm: 300;
  --_1ivttc5hn: 400;
  --_1ivttc5ho: 500;
  --_1ivttc5hp: 700;
  --_1ivttc5hq: 1;
  --_1ivttc5hr: 1.2;
  --_1ivttc5hs: 1.3;
  --_1ivttc5ht: 1.4;
  --_1ivttc5hu: 1.6;
  --_1ivttc5hv: 1.8;
  --_1ivttc5hw: 2.0;
  --_1ivttc5hx: 0;
  --_1ivttc5hy: 1;
  --_1ivttc5hz: 2;
  --_1ivttc5i0: 3;
  --_1ivttc5i1: 4;
  --_1ivttc5i2: 5;
  --_1ivttc5i3: 1px;
  --_1ivttc5i4: 2px;
}