.vvbgwx0 {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - var(--_12k45md3));
  background-color: var(--_6q1didw);
  overflow: hidden;
}
._12k45md8 ._1bndnd21 ._1bndnd21 .vvbgwx0 {
  height: 100%;
}
.vvbgwx1 {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: var(--_12k45md1);
  padding-left: 24px;
  padding-right: 24px;
  box-shadow: inset 0px -1px 0px 0px var(--_6q1did10);
  flex: 0 0 auto;
}
.vvbgwx1 ._964fy0 {
  padding: 0;
  width: auto;
}
.vvbgwx1 ._15gj9s20 {
  margin: 0;
  line-height: 1;
}
.vvbgwx2 {
  position: absolute;
  top: calc((var(--_12k45md1) - var(--_12k45md5)) / 2);
  right: 7.407407407407407vw;
  font-size: var(--_12k45md5);
  width: var(--_12k45md5);
  height: var(--_12k45md5);
  padding: 0;
}
.vvbgwx2 ._15gj9s20 {
  width: var(--_12k45md5);
  height: var(--_12k45md5);
}
.vvbgwx5 {
  margin-left: 8px;
}
.vvbgwx6 {
  padding-left: 0px;
  padding-right: 0px;
}
.vvbgwx7:before {
  content: "";
  background: var(--_6q1did1j);
  width: 4px;
  height: 4px;
  border-radius: 10px;
  position: relative;
  left: 13px;
  top: -1.6px;
}
.vvbgwx8 {
  display: grid;
  align-items: center;
  position: relative;
}
.vvbgwx9 {
  position: absolute;
  top: var(--_12k45md1);
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--_6q1didw);
}
.vvbgwxa {
  margin-left: 24px;
  margin-top: 24px;
}
@media screen and (min-width:48em) {
  .vvbgwxa {
    margin-left: 24px;
  }
}
@media screen and (min-width:64em) {
  .vvbgwx1 {
    padding-left: 48px;
    padding-right: 48px;
  }
  .vvbgwx2 {
    right: 3.7037037037037037vw;
  }
  .vvbgwxa {
    margin-left: 48px;
  }
}