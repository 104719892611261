._1d6pm090 {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: -40px 0 48px 0;
}
._1d6pm091 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
._1d6pm092 {
  margin: 16px;
  height: 60px;
  padding: 0 28px 0 28px;
  display: flex;
  justify-content: center;
}
._1d6pm093 {
  font-size: var(--_1ivttc5hb);
  color: var(--_6q1didw);
  fill: var(--_6q1didw);
  margin-left: 12px;
}
._1d6pm094 {
  position: relative;
  width: 100%;
  display: block;
}
._1d6pm095 {
  height: 60px;
  width: 100%;
  margin: 16px 0;
  padding: 32px 20px 16px 28px;
  border-style: solid;
  border-color: var(--_6q1did13);
  color: var(--_6q1didw);
  border-width: 2px;
  border-radius: 80px;
  font-size: var(--_1ivttc5h9);
  outline: none;
  background-color: var(--_6q1didx);
}
._1d6pm096 {
  border-color: var(--_6q1did1i);
}
._1d6pm097 {
  border-color: var(--_6q1did14);
}
._1d6pm098 {
  padding: 0 16px;
  position: absolute;
  z-index: 1;
  left: 12px;
  color: var(--_6q1didw);
  transition: transform 0.3s ease-in-out, font-size 0.3s ease-in-out;
  top: 50%;
  transform: translateY(-50%);
  font-size: var(--_1ivttc5h9);
  cursor: text;
}
._1d6pm099 {
  transform: translateY(-130%);
  font-size: var(--_1ivttc5h7);
}
@media screen and (min-width:48em) {
  ._1d6pm094 {
    width: 544px;
  }
}
@media screen and (min-width:64em) {
  ._1d6pm090 {
    margin: -80px 0 48px 0;
  }
  ._1d6pm094 {
    width: 560px;
  }
}