._1cm2qjg0 {
  --_1mwrmpl0: grid;
  --_1mwrmpl1: -12px;
  --_1mwrmpl2: 8px 12px 8px 8px;
  --_1mwrmpl3: 8px;
  --_1mwrmpl4: 1;
  --_1mwrmpl5: var(--_6q1didw);
  --_1mwrmpl6: var(--_6q1didw);
  --_1mwrmpl7: 0px;
  --_1mwrmpl8: 400;
}