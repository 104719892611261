._175o57z1 {
  width: 100%;
  border-radius: 12px 12px 0px 0px;
  padding: 24px 14px 18px 14px;
}
._175o57z2 {
  width: 100%;
  border-radius: 0px 0px 12px 12px;
  border: 1px solid #DEDEDE;
  padding: 14px 24px;
}
._175o57z3 {
  padding-top: 54.4px;
}
._175o57z4 {
  padding-top: 32px;
}
._175o57z5 {
  margin-bottom: var(--_1ivttc56);
}
._175o57z2 p {
  width: 100%;
  max-width: 100%;
}
._175o57z3, ._175o57z4 {
  flex-direction: column;
}
._175o57z4 {
  flex-direction: column;
}
._175o57z3 a, ._175o57z4 a {
  border-radius: 50px;
  font-size: var(--_1ivttc5h7);
  padding: 10px 22px;
}
@media screen and (min-width:64em) {
  ._175o57z1 {
    padding: 36px 36px 32px 36px;
  }
  ._175o57z2 {
    padding: 32px 36px 62px 36px;
  }
  ._175o57z5 {
    margin-bottom: 0;
  }
  ._175o57z3, ._175o57z4 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  ._175o57z4 {
    justify-content: flex-start;
  }
  ._175o57z3 a, ._175o57z4 a {
    padding: 17px 32px;
    font-size: var(--_1ivttc5h9);
  }
}