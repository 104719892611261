@keyframes _1mwrmpls {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
._1mwrmpl9 {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 1fr, auto;
  grid-template-areas: 'header' 'content';
}
._1mwrmpla {
  display: flex;
  grid-area: header;
  justify-content: center;
  margin: 8px;
}
._1mwrmplb {
  display: flex;
  grid-area: header;
  justify-content: center;
}
._1mwrmplc {
  margin: 5px;
  width: 71px;
  height: 1px;
  background: var(--_6q1did10);
  top: 10px;
  position: relative;
}
._1mwrmpld {
  width: 30px;
}
._1mwrmple {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 30px;
  height:  30px;
  border-style: solid;
  border-width: 1px;
  border-radius: 50%;
}
._1mwrmplf {
  margin-left: auto;
  margin-right: auto;
  width: 30px;
  line-height: 30px;
  text-align: center;
  font-size: var(--_1ivttc5h9);
  position: absolute;
}
._1mwrmplg {
  top: 3px;
  left: 5px;
  position: absolute;
}
._1mwrmplh {
  display: block;
  font-size: var(--_1ivttc5h7);
  padding: 5px;
  text-align: center;
  margin-left: -100%;
  margin-right: -100%;;
  white-space: nowrap;
}
._1mwrmpli {
  transition-property: color;
  transition-duration: 1s;
  color: var(--_6q1did10);
}
._1mwrmplj {
  color: var(--_6q1did15);
  transition-property: color;
  transition-duration: 1s;
}
._1mwrmplk {
  display: flex;
  grid-area: content;
  overflow-x: hidden;
  height: 100%;
}
._1mwrmpll {
  min-width: 100%;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-areas: 'card';
}
._1mwrmpll[data-cardselected="true"] {
  display: grid;
}
._1mwrmpll[data-cardselected="false"] {
  display: none;
}
._1mwrmplm {
  display: grid;
  grid-template-rows: 1fr auto auto;
  grid-template-areas: 'content' 'cta' 'footer';
  min-width: 100%;
}
._1mwrmpln {
  grid-area: content;
  margin: auto;
}
._1mwrmplo {
  display: flex;
  grid-area: cta;
}
._1mwrmplp {
  grid-area: footer;
  padding: 0 16px 0 16px;
  min-width: 100%;
  text-align: center;
}
._1mwrmplq {
  position: relative;
  height: 100%;
}
._1mwrmplr {
  margin: 0;
  position: relative;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
}
._1mwrmplt {
  margin-top: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  animation-name: _1mwrmpls;
  animation-duration: 1s;
}
._1mwrmplu {
  position: relative;
  width: 100%;
  padding: var(--_1mwrmpl3);
  display: block;
}
._1mwrmplx {
  padding: 10px;
  background-color: var(--_6q1didw);
  border-style: solid;
  border-color: var(--_6q1did10);
  color: var(--_6q1didx);
  border-width: 1px;
  border-radius: 10px;
  outline: none;
  -webkit-appearance: none;
  width: 100%;
}
._1mwrmplv[data-selecttype="checkboxGrid"] ._1mwrmplx {
  border-color: var(--_1mwrmpl5);
  padding: var(--_1mwrmpl7);
}
._1mwrmplv[data-selecttype="checkboxGrid"] ._1mwrmplx[data-error="true"] {
  border-color: var(--_6q1did1i);
  padding: 10px;
}
._1mwrmplx:focus-within {
  border-color: var(--_6q1did14);
}
._1mwrmplx[data-error="true"] {
  border-color: var(--_6q1did1i);
}
._1mwrmply {
  padding: 0 4px;
  color: var(--_6q1did1i);
  font-size: var(--_1ivttc5h7);
  height: 0px;
}
._1mwrmpl10 {
  padding: 0 4px;
  color: var(--_6q1did13);
  font-size: var(--_1ivttc5h7);
  height: 0px;
}
._1mwrmpl11 {
  position: relative;
}
._1mwrmpl12 {
  padding: 0 4px;
  position: absolute;
  z-index: 1;
  left: 4px;
  color: var(--_6q1did13);
  transition: font-size 0.3s ease-in-out, top 0.3s ease-in-out ;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  font-size: var(--_1ivttc5h9);
  background-color: var(--_6q1didw);
}
._1mwrmpl13 {
  top: var(--_1mwrmpl1);
  font-size: var(--_1ivttc5h7);
}
._1mwrmpl14 {
  color: var(--_6q1did1i);
}
._1mwrmpl15 {
  padding: var(--_1mwrmpl2);
  font-size: var(--_1ivttc5h9);
  width: 100%;
  outline: none;
  resize: none;
  border: 0;
  background-color: var(--_6q1didw);
  -webkit-appearance: none;
}
._1mwrmpl15:focus {
  outline: none;
}
._1mwrmpl16 {
  font-size: var(--_1ivttc5h9);
  font-weight: var(--_1mwrmpl8);
}
._1mwrmpl17 {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
  font-size: var(--_1ivttc5h7);
  pointer-events: none;
}
._1mwrmpl18 {
  display: flex;
  font-size: var(--_1ivttc5h9);
  cursor: pointer;
}
._1mwrmpl19 {
  min-height: 30px;
  min-width: 30px;
  margin-right: 20px;
  border: 1px solid var(--_6q1didx);
  background-color: var(--_6q1didw);
  align-self: center;
  border-color: var(--_6q1did13);
}
._1mwrmpl19:focus-within {
  border-color: var(--_6q1did14);
  box-shadow: 1px 1px 1px gray;
}
._1mwrmpl19[data-type="checkbox"] {
  border-radius: 6px;
}
._1mwrmpl19[data-type="radio"] {
  border-radius: 15px;
}
._1mwrmpl1a {
  width: var(--_1ivttc5h8);
  height: var(--_1ivttc5h8);
  margin: 6px;
  position: relative;
  top: 1px;
  left: 1px;
  color: var(--_6q1didx);
}
._1mwrmpl1b {
  border-width: 0;
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  margin: -1px;
}
._1mwrmpl1c {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
._1mwrmplv[data-selecttype="checkboxGrid"] ._1mwrmpl1c {
  font-size: var(--_1ivttc5h8);
}
._1mwrmpl1d {
  margin: 16px;
  display: flex;
  justify-content: center;
  flex-grow: var(--_1mwrmpl4);
}
._1mwrmpl1d:focus {
  opacity: 0.5;
}
@media screen and (min-width:48em) {
  ._1mwrmpll[data-cardselected="true"] {
    display: grid;
    visibility: visible;
  }
  ._1mwrmpll[data-cardselected="false"] {
    display: var(--_1mwrmpl0);
    visibility: hidden;
  }
  ._1mwrmplu[data-columns="2"] {
    width: 50%;
  }
  ._1mwrmplu[data-columns="3"] {
    width: 33%;
  }
}