.zjvqie0 {
  display: flex;
  justify-content: center;
  margin: 0 0 48px 0;
}
.zjvqie1 {
  max-width: 100%;
  margin-top: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.zjvqie2 {
  margin: 16px;
  display: flex;
  justify-content: center;
}
.zjvqie3 {
  font-size: var(--_1ivttc5hb);
  color: var(--_6q1didw);
  fill: var(--_6q1didw);
  margin-left: 12px;
}
.zjvqie4 {
  position: relative;
  left: 22px;
  color: var(--_6q1did1i);
}
.zjvqie5 {
  height: 68px;
  width: 100%;
  margin: 16px 0;
  padding: 32px 20px 16px 8px;
  background-color: var(--_6q1didw);
  border-style: solid;
  border-color: var(--_6q1did10);
  color: var(--_6q1didx);
  border-width: 1px;
  border-radius: 10px;
  font-size: var(--_1ivttc5h9);
  outline: none;
  position: relative;
  -webkit-appearance: none;
}
.zjvqie6 {
  border-color: var(--_6q1did1i);
}
.zjvqie7 {
  border-color: var(--_6q1did14);
}
.zjvqie8 {
  position: relative;
  width: 100%;
  display: block;
}
.zjvqie9 {
  position: relative;
  width: 100%;
  display: block;
  margin-bottom: 32px;
}
.zjvqiea {
  margin: 16px 0;
  padding: 32px 20px 16px 8px;
  border-style: solid;
  border-color: var(--_6q1did10);
  border-width: 1px;
  border-radius: 10px;
  font-family: var(--_1ivttc50);
  font-size: var(--_1ivttc5h9);
  outline: none;
  height: 112px;
  min-width: 100%;
  flex-basis: auto;
}
.zjvqieb {
  border-color: var(--_6q1did1i);
}
.zjvqiec {
  border-color: var(--_6q1did14);
}
.zjvqied {
  padding: 0 4px;
  position: absolute;
  z-index: 1;
  left: 4px;
  color: var(--_6q1did13);
  top: 28%;
  transition: transform 0.3s ease-in-out, font-size 0.3s ease-in-out;
  font-size: var(--_1ivttc5h9);
}
.zjvqiee {
  transform: translateY(-60%);
  font-size: var(--_1ivttc5h7);
}
.zjvqief {
  padding: 0 4px;
  position: absolute;
  z-index: 1;
  left: 4px;
  color: var(--_6q1did13);
  transition: transform 0.3s ease-in-out, font-size 0.3s ease-in-out;
  top: 50%;
  transform: translateY(-50%);
  font-size: var(--_1ivttc5h9);
}
.zjvqieg {
  transform: translateY(-130%);
  font-size: var(--_1ivttc5h7);
}
.zjvqieh {
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  font-size: var(--_1ivttc5h9);
  cursor: pointer;
}
.zjvqiei {
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  font-size: var(--_1ivttc5h9);
  cursor: default;
}
.zjvqiej {
  min-height: 30px;
  min-width: 30px;
  margin-right: 20px;
  border: 1px solid var(--_6q1didx);
  border-radius: 6px;
  background-color: var(--_6q1didw);
  align-self: center;
}
.zjvqiek {
  min-height: 30px;
  min-width: 30px;
  margin-right: 20px;
  border: 1px solid var(--_6q1didx);
  border-radius: 6px;
  background-color: #F4F4F4;
  align-self: center;
}
.zjvqiel {
  min-height: 40px;
  min-width: 40px;
  margin-right: 20px;
  border: 1px solid var(--_6q1didx);
  border-radius: 50%;
  background-color: var(--_6q1didw);
  align-self: center;
}
.zjvqiem {
  border-width: 0;
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  margin: -1px;
}
.zjvqien {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.zjvqieo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #ABABAB;
}
.zjvqiep {
  width: var(--_1ivttc5h8);
  height: var(--_1ivttc5h8);
  margin: 6px;
  position: relative;
  top: 1px;
  left: 1px;
  color: var(--_6q1didx);
}
.zjvqieq {
  width: var(--_1ivttc5h8);
  height: var(--_1ivttc5h8);
  margin: 10px;
  position: relative;
  top: 1px;
  left: 1px;
  color: var(--_6q1didx);
}
.zjvqier {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
  font-size: var(--_1ivttc5h7);
}
.zjvqies {
  color: var(--_6q1did1i);
}
@media screen and (min-width:48em) {
  .zjvqie8[data-span=half] {
    width: 48%;
  }
  .zjvqie9[data-span=half] {
    width: 48%;
  }
}