._1cnty1i0 {
  width: 100%;
}
._1cnty1i0 > div > * + *:not(p,ul,ol) {
  margin-bottom: var(--_1ivttc56);
  margin-top: var(--_1ivttc57);
}
._1cnty1i0 a:not([type='button']) {
  color: var(--rcioba0);
}
._1cnty1i0 > div > * + p {
  margin-top: var(--_1ivttc56);
}
._1cnty1i1 li {
  padding-top: var(--_1ivttc55);
  padding-bottom: var(--_1ivttc55);
}
._1cnty1i1 ul, ._1cnty1i1 ol {
  padding-top: var(--_1ivttc55);
  padding-left: var(--_1ivttc56);
  margin-right: 0;
  margin-left: 0;
}
._1cnty1i2 {
  margin-bottom: var(--_1ivttc56);
}
._1cnty1i3 {
  font-size: var(--_1ivttc5h9);
}
@media screen and (min-width:64em) {
  ._1cnty1i0 > div > * + *:not(p,ul,ol) {
    margin-top: var(--_1ivttc53c);
  }
}