._1l8zmlv0 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: var(--_1ivttc5cz);
  position: relative;
  margin: 0 auto;
  padding-bottom: 12px;
}
._1l8zmlv1 {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--_6q1didz);
  height: var(--_1ivttc5cz);
  width: var(--_1ivttc5cz);
  margin-top: 0px;
  padding: var(--_1ivttc5cq);
}
._1l8zmlv0:hover > button {
  color: var(--_6q1didx);
}
._1l8zmlv0 ._15gj9s20 {
  width: 32px;
}
._1l8zmlv1 .n6sgiyc {
  display: none;
}
._1l8zmlv1 ._12zni9u1 {
  width: var(--_1ivttc5cx);
}
._1l8zmlv1._12zni9u1 {
  padding: var(--_1ivttc5cq);
}
._1l8zmlv1 .sdvz5o1 {
  padding: 8px;
}
._1l8zmlv0 ._16fti0n3 {
  padding: 0;
}
._1l8zmlv2 {
  flex: 0;
}
._1l8zmlv3 {
  display: flex;
  font-size: var(--_1ivttc5h7);
  padding-top: 20px;
  padding-bottom: 12px;
  font-weight: var(--_1ivttc5ho);
}
._1l8zmlv4 {
  display: flex;
  margin-top: 8px;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 8px;
}
._1l8zmlv5 {
  margin-bottom: auto;
  flex: 1;
  display: inline-block;
  font-size: var(--_1ivttc5h7);
  margin-top: 0;
}
._1l8zmlv6 {
  display: flex;
  gap: 8px;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
}
._1l8zmlv7 {
  position: absolute;
  color: var(--_6q1didu);
  transition: color 0.2s ease-in;
  z-index: 2;
  top: 32px;
  left: 32px;
  width: var(--_1ivttc52);
  font-size: var(--_1ivttc5h7);
  padding: 0px;
  padding-right: 16px;
  display: none;
}
._1l8zmlv7 > ._15gj9s20 {
  margin-left: 0px;
  margin-right: 8px;
  width: 20px;
}
._1l8zmlv8 {
  margin-top: 8px;
  display: block;
  font-size: var(--_1ivttc5h7);
}
._1l8zmlv9 {
  margin-top: 24px;
}
._1l8zmlva {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: auto;
}
._1l8zmlvb {
  margin-left: -4px;
}
._1l8zmlvc {
  display: flex;
  flex: 1;
}
._1l8zmlvd {
  display: flex;
  min-width: 137px;
}
._1l8zmlve {
  position: absolute;
  right: var(--_1ivttc5dh);
  top: var(--_1ivttc5dh);
  z-index: 1;
  padding-top: 0;
}
._1l8zmlvf {
  position: relative;
}
._1l8zmlvf:after {
  content: '';
  display: block;
  width: 100%;
  height: 0;
  padding-top: 100%;
}
._1l8zmlvg {
  color: var(--_6q1did13);
  font-size: var(--_1ivttc5h7);
  padding-top: 4px;
  padding-bottom: 12px;
  width: 100%;
}
._1l8zmlvf ._1l8zmlve {
  padding-top: 0;
}
@media screen and (min-width:48em) {
  ._1l8zmlv1 {
    padding-bottom: 36px;
  }
  ._1l8zmlv1 .n6sgiyc {
    display: block;
    padding: 8px;
  }
  ._1l8zmlv3 {
    font-size: var(--_1ivttc5ha);
  }
}
@media screen and (min-width:64em) {
  ._1l8zmlv1 .n6sgiyc {
    padding: 24px;
  }
  ._1l8zmlv1 ._12zni9u1 {
    width: var(--_1ivttc5dp);
  }
  ._1l8zmlv1._12zni9u1 {
    padding: calc(var(--_1ivttc5dh) + 36px);
  }
  ._1l8zmlv3 {
    padding-top: 24px;
    font-size: var(--_1ivttc5hc);
  }
  ._1l8zmlv4 {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: var(--_1ivttc53);
  }
  ._1l8zmlv5 {
    font-size: var(--_1ivttc5ha);
    margin-top: auto;
  }
  ._1l8zmlv6 {
    flex-direction: row;
    align-items: center;
  }
  ._1l8zmlv7 {
    display: inline-flex;
  }
  ._1l8zmlv8 {
    display: none;
    font-size: var(--_1ivttc5ha);
  }
  ._1l8zmlva {
    flex-direction: row;
    margin-top: auto;
  }
  ._1l8zmlvb {
    margin-left: 0;
  }
  ._1l8zmlvc {
    display: none;
  }
}