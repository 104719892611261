._1gmcvk10 {
  padding-left: huge;
}
._1gmcvk10 li {
  padding-top: var(--_1ivttc55);
  padding-bottom: var(--_1ivttc55);
}
._1gmcvk10 ul, ._1gmcvk10 ol {
  padding-top: var(--_1ivttc55);
  padding-left: var(--_1ivttc56);
  margin-right: 0;
  margin-left: 0;
}
._1gmcvk11 {
  vertical-align: middle;
  display: inline-flex;
  margin-right: var(--_1ivttc53b);
}
._1gmcvk12 svg {
  height: 100%;
  width: var(--_1ivttc51);
}
._1gmcvk13  svg {
  font-size: var(--_1ivttc5hb);
}
._1gmcvk14 {
  text-decoration: underline;
}
._1gmcvk15 {
  display: inline-flex;
  width: initial;
  align-self: start;
  justify-self: initial;
  margin-bottom: 0;
}
._1gmcvk16 {
  display: grid;
  grid-template-columns: auto 1fr;
}
._1gmcvk17 {
  display: flex;
  flex-direction: column;
  width: 100%;
}
._1gmcvk18 {
  padding: 0 !important;
  text-decoration: underline;
}
@media screen and (min-width:64em) {
  ._1gmcvk13  svg {
    font-size: var(--_1ivttc5hc);
  }
}