._9izavu0 {
  margin-left: var(--_1ivttc53a);
  display: flex;
  align-items: center;
}
._9izavu0 svg {
  fill: currentColor;
}
._9izavu0 svg path, ._9izavu0 svg rect {
  fill: currentColor;
}
._9izavu1 {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  color: currentColor;
  cursor: pointer;
  width: 100%;
  font-size: var(--_1ivttc5h9);
}
._9izavu2 {
  width: var(--_1ivttc5dg);
  margin: auto;
  padding-top: var(--_1ivttc5e);
}
._9izavu3 {
  margin-left: var(--_1ivttc5cr);
  margin-right: var(--_1ivttc5cr);
  border-top: 1px solid var(--_6q1did10);
  border-bottom: 1px solid var(--_6q1did10);
  padding-top: var(--_1ivttc58);
  padding-bottom: var(--_1ivttc58);
}
._9izavu2 ._9izavu3:first-child {
  border-top: none;
}
._9izavu2 ._9izavu3:last-child {
  border-bottom: none;
}
._9izavu4 {
  display: grid;
  grid-column-gap: var(--_1ivttc5cr);
  margin-top: var(--_1ivttc5a);
  margin-bottom: var(--_1ivttc5a);
  grid-template-columns: 1fr;
  grid-row-gap: var(--_1ivttc58);
}
._9izavu5 {
  display: flex;
  flex-direction: column;
}
._9izavu7 {
  margin-top: var(--_1ivttc57);
}
._9izavu7 a {
  color: var(--rcioba0);
}
._9izavu8 {
  width: var(--_1ivttc5cz);
  margin-top: var(--_1ivttc5a);
  margin-bottom: var(--_1ivttc5a);
}
._9izavu8 p {
  margin-top: var(--_1ivttc57);
}
._9izavu9 {
  margin-top: var(--_1ivttc5a);
}
.pr-closed [data-testid="review-image-display"] {
  display: none;
}
._9izavua {
  transition: transform 0.5s linear;
  transform: scale(1);
  overflow-y: scroll;
  height: auto;
}
._9izavub {
  overflow: hidden;
  transition: transform 0.5s linear;
  transform: scale(0);
  margin-top: 0;
  height: 0;
}
@media screen and (min-width:64em) {
  ._9izavu1 {
    font-size: var(--_1ivttc5he);
  }
  ._9izavu2 {
    padding-top: var(--_1ivttc5i);
  }
  ._9izavu3 {
    padding-top: var(--_1ivttc5a);
    padding-bottom: var(--_1ivttc5a);
  }
  ._9izavu4 {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: var(--_1ivttc5e);
  }
}