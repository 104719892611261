.x58f780 {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: var(--_1ivttc5fq);
  word-wrap: break-word;
}
.x58f781 {
  width: var(--_1ivttc53);
}
.x58f781 button > svg {
  max-width: 40px;
}