._12zni9u0 {
  padding-bottom: 8px;
  margin-bottom: 0px;
}
._12zni9u1 {
  height: var(--_1ivttc5fu);
  width: var(--_1ivttc5cx);
  padding-left: var(--_1ivttc5dh);
  padding-right: var(--_1ivttc5dh);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
._12zni9u0 .n6sgiy9 {
  transform: scale(0.6);
  transition: all .1s ease-in;
  opacity: 0.27;
  background-color: var(--_6q1didu);
}
._12zni9u0 .n6sgiy9:hover {
  transform: scale(0.7);
  opacity: 0.77;
}
@media screen and (min-width:48em) {
  ._12zni9u0 {
    padding-bottom: 68px;
  }
  ._12zni9u0 .n6sgiy8 {
    display: flex;
  }
}
@media screen and (min-width:64em) {
  ._12zni9u0 {
    padding-bottom: 36px;
  }
}