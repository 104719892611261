.nz74ni0 {
  display: flex;
  justify-content: center;
  width: 85.18518518518519vw;
}
.nz74ni1 {
  display: flex;
  flex-direction: row;
}
.nz74ni2 {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.nz74ni3 {
  font-size: var(--_1ivttc5h9);
  padding-bottom: 13px;
  opacity: 0.5;
  border-bottom: 2px solid;
  border-color: transparent;
  text-align: center;
  font-weight: var(--_1ivttc5ho);
  margin-top: 24px;
}
.nz74ni4 {
  border-color: var(--_6q1did15);
  opacity: 1;
}
.nz74ni5 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.nz74ni6 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.nz74ni7 {
  border: 1px solid var(--_6q1did10);
  height: 35px;
  margin-left: 45px;
  margin-right: 45px;
}
.nz74ni5:last-child {
  border-right: none;
}