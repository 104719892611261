._15usmcc2 {
  border-radius: 50%;
  border: 1px solid;
  border-color: var(--_15usmcc0);
  color: var(--_15usmcc0);
  background-color: var(--_15usmcc1);
}
._15usmcc3 {
  padding: 2px 8px;
}
._15usmcc4 {
  padding: 4px 4px 0px 4px;
}
@media screen and (min-width:64em) {
  ._15usmcc4 {
    padding: 4px 4px 2px 4px;
  }
}