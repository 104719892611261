._2dzoi30 {
  background-color: var(--_6q1did1i);
  text-align: center;
  width: 100vw;
  color: var(--_6q1didx);
}
._2dzoi31 {
  font-size: var(--_1ivttc5h7);
  padding: 8px;
  margin: 4px;
}