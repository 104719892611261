._1ett7rc0 {
  flex-direction: column;
}
._1ett7rc1 {
  height: var(--_1ivttc53);
}
._1ett7rc2 {
  position: absolute;
  padding: 0;
  z-index: -1;
}
@media screen and (min-width:64em) {
  ._1ett7rc0 {
    flex-direction: row;
  }
}