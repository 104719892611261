._1ay5pdg0 {
  z-index: 2;
  align-content: center;
}
._1ay5pdg0 .v6jvm52 {
  width: var(--_1ivttc5fq);
}
@media screen and (min-width:90em) {
  ._1ay5pdg0 > .v6jvm57 > .v6jvm54 {
    max-width: var(--_1ivttc5fq);
  }
}