:root {
  --_1bndnd20: 11;
}
._1bndnd21 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: var(--_1bndnd20);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--_1ivttc54) var(--_1ivttc54);
  overflow: hidden;
}
._1bndnd22 {
  padding: var(--_1ivttc54) var(--_1ivttc54);
}
._1bndnd23 {
  align-items: flex-start;
  height: var(--_1ivttc52);
}
._1bndnd24 {
  background-color: rgba(0,0,0,0.8);
}
._1bndnd25 {
  width: var(--_1ivttc5de);
  background-color: white;
  border-radius: 14px;
  box-shadow: 0px 0px 25px 6px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  padding-left: var(--_1ivttc55);
  padding-right: var(--_1ivttc55);
  padding-top: var(--_1ivttc56);
  padding-bottom: var(--_1ivttc56);
}
._1bndnd26 {
  max-height: 100%;
  top: var(--_1ivttc5a);
}
._1bndnd27 {
  max-height: var(--_1ivttc5o);
  top: var(--_1ivttc5a);
}
._1bndnd28 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--_1ivttc58);
}
._1bndnd29 {
  padding: 0;
  z-index: 5;
}
._1bndnd2a {
  font-size: var(--_1ivttc5h9);
  font-weight: var(--_1ivttc5ho);
}
._1bndnd2b {
  margin: 0;
}
._1bndnd2b svg {
  fill: inherit;
  font-size: var(--_1ivttc5h9);
}
._1bndnd2b svg path {
  fill: inherit;
}
@media screen and (min-width:48em) {
  ._1bndnd21 {
    overflow: initial;
  }
}
@media screen and (min-width:64em) {
  ._1bndnd21 {
    padding: var(--_1ivttc57) var(--_1ivttc5cq);
  }
  ._1bndnd22 {
    padding: var(--_1ivttc5q) var(--_1ivttc5cu) var(--_1ivttc5y);
  }
  ._1bndnd25 {
    padding: var(--_1ivttc53d);
  }
  ._1bndnd26 {
    top: var(--_1ivttc5c);
  }
  ._1bndnd27 {
    max-height: var(--_1ivttc5y);
    top: var(--_1ivttc5c);
  }
  ._1bndnd2a {
    font-size: var(--_1ivttc5hc);
    line-height: var(--_1ivttc5hr);
    font-weight: var(--_1ivttc5ho);
  }
  ._1bndnd2b svg {
    font-size: var(--_1ivttc5hc);
  }
}