._1sg9d510 {
  display: block;
  font-size: var(--_1ivttc5hc);
  line-height: var(--_1ivttc5hr);
}
._1sg9d511 {
  display: block;
  line-height: var(--_1ivttc5hr);
  font-weight: var(--_1ivttc5ho);
  font-size: var(--_1ivttc5hf);
}
._1sg9d512 {
  display: block;
  line-height: var(--_1ivttc5hr);
  font-weight: var(--_1ivttc5ho);
  font-size: var(--_1ivttc5he);
}
._1sg9d513 {
  display: block;
  line-height: var(--_1ivttc5hr);
  font-weight: var(--_1ivttc5ho);
  font-size: var(--_1ivttc5hc);
}
._1sg9d514 {
  display: block;
  font-size: var(--_1ivttc5ha);
  line-height: var(--_1ivttc5hr);
  font-weight: var(--_1ivttc5ho);
}
._1sg9d515 {
  display: block;
  font-size: var(--_1ivttc5hc);
  line-height: var(--_1ivttc5hr);
  font-weight: var(--_1ivttc5hn);
}
._1sg9d516 {
  display: block;
  font-size: var(--_1ivttc5hc);
  line-height: var(--_1ivttc5hr);
  font-weight: var(--_1ivttc5ho);
}
._1sg9d517 {
  display: block;
  font-size: var(--_1ivttc5ha);
  line-height: var(--_1ivttc5hs);
  font-weight: var(--_1ivttc5ho);
}
._1sg9d518 {
  display: block;
  font-size: var(--_1ivttc5h8);
  line-height: var(--_1ivttc5hs);
  font-weight: var(--_1ivttc5hn);
}
._1sg9d519 {
  display: block;
  font-size: var(--_1ivttc5ha);
  line-height: var(--_1ivttc5ht);
  font-weight: var(--_1ivttc5ho);
}
._1sg9d51a {
  display: block;
  font-size: var(--_1ivttc5h9);
  line-height: var(--_1ivttc5ht);
  font-weight: var(--_1ivttc5hn);
}
._1sg9d51b {
  display: block;
  font-size: var(--_1ivttc5h8);
  line-height: var(--_1ivttc5ht);
  font-weight: var(--_1ivttc5ho);
}
._1sg9d51c {
  display: block;
  font-size: var(--_1ivttc5h8);
  line-height: var(--_1ivttc5ht);
  font-weight: var(--_1ivttc5hn);
}
._1sg9d51d {
  display: block;
  font-size: var(--_1ivttc5h7);
  line-height: var(--_1ivttc5ht);
  font-weight: var(--_1ivttc5hn);
  opacity: 0.98;
  max-width: var(--_1ivttc5d5);
  color: var(--_6q1did13);
}
._1sg9d51e {
  display: block;
  line-height: var(--_1ivttc5ht);
  font-weight: var(--_1ivttc5hn);
  opacity: 0.5;
  font-size: var(--_1ivttc5h6);
  max-width: var(--_1ivttc5d5);
}
._1sg9d51f {
  display: block;
}
._1sg9d51g {
  flex: 1;
}
@media screen and (min-width:64em) {
  ._1sg9d511 {
    font-size: var(--_1ivttc5hi);
  }
  ._1sg9d512 {
    font-size: var(--_1ivttc5hh);
  }
  ._1sg9d513 {
    font-size: var(--_1ivttc5hg);
  }
  ._1sg9d514 {
    font-size: var(--_1ivttc5he);
  }
  ._1sg9d515 {
    font-size: var(--_1ivttc5hd);
  }
  ._1sg9d518 {
    font-size: var(--_1ivttc5ha);
  }
  ._1sg9d51d {
    max-width: var(--_1ivttc5cu);
  }
  ._1sg9d51e {
    font-size: var(--_1ivttc5h6);
    max-width: var(--_1ivttc5cu);
  }
}