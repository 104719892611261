._16fti0n0 {
  margin-left: auto;
}
._16fti0n1 {
  margin-left: 0;
}
._16fti0n2 {
  margin-left: 0;
}
._16fti0n3 {
  display: flex;
  align-items: center;
}
._16fti0n4 {
  display: inline-block;
}
._16fti0n4 > label {
  display: inline;
  padding-bottom: 10px;
  margin-right: 35px;
}
._16fti0n4 > label span {
  position: relative;
  margin-bottom: 25px;
}
._16fti0n5 {
  display: inline-flex;
  gap: 24px;
}
._16fti0n6 {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid;
  border-bottom-color: var(--_6q1did10);
}
@media screen and (min-width:64em) {
  ._16fti0n4 > label {
    margin-right: 15px;
  }
}