._1cd5ljj0 {
  margin-left: 8px;
}
._1cd5ljj1 {
  display: flex;
  white-space: nowrap;
  font-weight: var(--_1ivttc5hn);
  align-items: center;
}
._1cd5ljj2 {
  opacity: 50%;
}
._1cd5ljj3 {
  font-weight: var(--_1ivttc5ho);
}