.ks23ei0 {
  width: 1em;
}
.ks23ei1 {
  background-color: var(--_6q1did15);
  color: var(--_6q1did12);
  width: var(--_1ivttc5fq);
  height: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  bottom: 0;
}
.ks23ei2 {
  order: 1;
  margin-left: auto;
  display: flex;
  align-items: baseline;
  cursor: pointer;
}
.ks23ei3 {
  margin-left: 8px;
  margin-right: 8px;
}
.ks23ei4 {
  margin-left: 8px;
}
.ks23ei5 {
  width: var(--_1ivttc5dg);
  padding: var(--_1ivttc5cr);
  padding-top: var(--_1ivttc5ct);
}
.ks23ei2 > div:first-child {
  min-width: 14px;
}
.ks23ei4 a {
  white-space: nowrap;
}
.ks23ei5 ul {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
}
.ks23ei5 li {
  display: flex;
  width: 100%;
  margin-bottom: 16px;
}
.ks23ei1 button {
  top: var(--_1ivttc5cq);
  right: var(--_1ivttc5cq);
}
@media screen and (min-width:64em) {
  .ks23ei1 {
    height: auto;
  }
  .ks23ei2 {
    margin-left: 0;
  }
  .ks23ei4 {
    min-width: var(--_1ivttc5cq);
  }
  .ks23ei5 {
    padding: var(--_1ivttc5cr);
  }
  .ks23ei5 ul {
    height: 280px;
    overflow: hidden;
    flex-wrap: wrap;
  }
  .ks23ei5 li {
    width: 24.5%;
    margin-bottom: 20px;
  }
  .ks23ei1 button {
    top: var(--_1ivttc5dh);
    right: var(--_1ivttc5dh);
  }
}