.v6jvm52 {
  display: flex;
  flex: 1 1 auto;
  width: var(--_1ivttc53);
}
.v6jvm52.v6jvm53 {
  border-radius: var(--v6jvm50);
  overflow: hidden;
}
.v6jvm55 {
  position: absolute;
  top: 0;
  bottom: 0;
  width: var(--_1ivttc5dg);
}
.v6jvm52.v6jvm56 > section > div {
  width: var(--_1ivttc53);
}
.v6jvm57 {
  overflow-x: clip;
  position: relative;
}
.v6jvm58 {
  max-width: var(--_1ivttc5dg);
  margin-bottom: 56px;
}
.v6jvm59 {
  background: var(--v6jvm51) center center;
  background-clip: content-box;
  background-size: cover;
}
.v6jvm5a {
  cursor: pointer;
}
.v6jvm5b {
  display: flex;
  flex-direction: row;
  width: 85.18518518518519vw;
  align-items: center;
  margin-bottom: 16px;
}
.v6jvm5c {
  font-size: var(--_1ivttc5h7);
  font-weight: var(--_1ivttc5ho);
  color: var(--_6q1did13);
}
.v6jvm5d {
  margin-left: 8px;
}
.v6jvm52 .mobile-o0 {
  order: 0;
}
.v6jvm52 .mobile-o1 {
  order: 1;
}
.v6jvm52 .mobile-o2 {
  order: 2;
}
@media screen and (min-width:90em) {
  .v6jvm52.v6jvm54 {
    max-width: var(--_1ivttc5dg);
  }
  .v6jvm55 {
    left: 50%;
    right: 50%;
    margin-left: -720px;
  }
  .v6jvm52.v6jvm56 {
    position: static;
    width: var(--_1ivttc5fq);
    justify-content: space-between;
  }
  .v6jvm57 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: var(--_1ivttc5fq);
    height: var(--_1ivttc53);
  }
}
@media screen and (min-width:64em) {
  .v6jvm58 {
    margin-bottom: 112px;
  }
  .v6jvm52 .desktop-o0 {
    order: 0;
  }
  .v6jvm52 .desktop-o1 {
    order: 1;
  }
  .v6jvm52 .desktop-o2 {
    order: 2;
  }
}