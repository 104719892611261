._1d2u0v40 {
  box-sizing: content-box;
  height: 100vh;
  position: relative;
  width: 100%;
}
._1d2u0v41 {
  background-image: linear-gradient(135deg, rgba(255,0,0,0.1) 25%, rgba(180,180,180,0.1) 25%, rgba(180,180,180,0.1) 50%, rgba(255,0,0,0.1) 50%, rgba(255,0,0,0.1) 75%, rgba(180,180,180,0.1) 75%, rgba(180,180,180,0.1) 100%);
  background-size: 40px 40px;
}
._1d2u0v42 {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  max-width: 100vw;
  position: sticky;
  top: 0;
  width: 100%;
}
._1d2u0v43 {
  display: flex;
  height: auto;
  justify-content: center;
  width: 100%;
}
._1d2u0v44 {
  background-color: rgba(0,218,252,0.15);
}
._1d2u0v45 {
  align-items: center;
  background-color: #DDDDDD;
  display: flex;
  flex-direction: column;
  font-size: 10px;
  font-weight: bold;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 0.6;
  padding: 0;
  top: 0;
  width: auto;
  z-index: 1000;
}
._1d2u0v45 p {
  padding: 5px 10px;
}