.n6sgiy1 {
  width: fit-content;
}
.n6sgiy2 {
  width: fit-content;
}
.n6sgiy3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
}
.n6sgiy4 {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  width: var(--_1ivttc53);
  margin-bottom: 24px;
  overflow: hidden;
}
.n6sgiy5 {
  justify-content: center;
  align-items: center;
  max-width: var(--_1ivttc5fq);
  height: var(--_1ivttc53);
  padding-top: 48px;
  padding-bottom: 48px;
}
.n6sgiy6 {
  flex-direction: column;
}
.n6sgiy7 {
  display: flex;
  align-items: center;
  min-height: 100%;
}
.n6sgiy8 {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  height: auto;
}
.n6sgiy8 + .n6sgiy8 {
  left: unset;
  right: 8px;
}
.n6sgiy9 {
  border: none;
  border-radius: var(--_1ivttc53);
  background-color: var(--_6q1didw);
  line-height: 0px;
  cursor: pointer;
  padding: 8px;
}
.n6sgiya {
  display: flex;
  position: absolute;
  overflow: hidden;
  width: 0px;
  z-index: -1;
}
.n6sgiyb {
  border-radius: var(--_1ivttc53);
  background-color: var(--_6q1didx);
  height: 8px;
  width: 8px;
  display: inline-block;
  cursor: pointer;
  margin-left: 4px;
  margin-right: 4px;
}
.n6sgiyc {
  text-align: center;
  width: var(--_1ivttc53);
  padding: 4px;
  white-space: nowrap;
  overflow: hidden;
  cursor: default;
  background: transparent;
}
.n6sgiyd {
  font-size: var(--_1ivttc5hc);
  margin: 4px;
}
@media screen and (min-width:48em) {
  .n6sgiy3 {
    margin-top: 64px;
  }
  .n6sgiy4 {
    margin-bottom: 64px;
    overflow: hidden;
  }
  .n6sgiy8 {
    display: flex;
  }
}
@media screen and (min-width:64em) {
  .n6sgiy6 {
    flex-direction: var(--n6sgiy0);
  }
  .n6sgiy8 {
    left: 24px;
    display: flex;
  }
  .n6sgiy8 + .n6sgiy8 {
    right: 24px;
  }
}