._1lkto10 {
  --_1mwrmpl0: none;
  --_1mwrmpl1: 10px;
  --_1mwrmpl2: 24px 12px 8px 16px;
  --_1mwrmpl3: 16px;
  --_1mwrmpl4: 0;
  --_1mwrmpl5: var(--_6q1did10);
  --_1mwrmpl6: var(--_6q1did14);
  --_1mwrmpl7: 10px;
  --_1mwrmpl8: 400;
}