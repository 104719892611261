@keyframes fadeInSlideText {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
._1l83w3b0 {
  width: var(--_1ivttc53);
  max-width: var(--_1ivttc5fq);
  overflow-x: hidden;
  position: relative;
  transition: opacity 1s ease-in;
  display: flex;
  justify-content: center;
}
._1l83w3b0 .n6sgiy9 {
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.25);
}
._1l83w3b1 {
  position: relative;
  display: flex;
  flex-direction: column;
  width: var(--_1ivttc53);
}
._1l83w3b0 ._1l83w3b3 ._1l83w3b1 {
  width: var(--_1ivttc53);
}
._1l83w3b4 {
  display: flex;
}
._1l83w3b5 {
  padding-top: var(--_1ivttc5cq);
  padding-bottom: var(--_1ivttc5cq);
}
._1l83w3b0 ._1l83w3b1 ._1ett7rc0 > ._1cnty1i0 {
  position: relative;
  overflow: hidden;
}
._1l83w3b0 ._1l83w3b1 a {
  position: static;
  right: 0;
  top: 0;
}
._1l83w3b0 ._1ett7rc0 > ._1cnty1i0 > * {
  width: 100%;
  text-align: center;
}
._1l83w3b0 ._1l83w3b1 img {
  pointer-events: none;
  max-width: 0;
}
._1l83w3b0 ._1l83w3b1 figure {
  width: 100%;
}
._1l83w3b0 ._1ett7rc0 {
  width: 100%;
}
._1l83w3b3 section section {
  transition: opacity 20s ease-in 0.5s;
}
._1l83w3b3 ._1l83w3b1 > section:nth-child(n+2) {
  opacity: 0;
}
._1l83w3b3[tabindex="1"] ._1l83w3b1 > section:nth-child(n+2) {
  animation: fadeInSlideText 2.25s;
  opacity: 1;
}
@media screen and (min-width:64em) {
  ._1l83w3b0 ._1ett7rc0 {
    margin-bottom: 24px;
  }
  ._1l83w3b5 {
    padding-top: var(--_1ivttc5fs);
    padding-bottom: var(--_1ivttc5fs);
  }
}
@media screen and (min-width:90em) {
  ._1l83w3b0 ._1l83w3b2 article {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: unset;
    height: unset;
    overflow: hidden;
  }
}
@media screen and (min-width:48em) {
  ._1l83w3b0 ._1l83w3b1 a {
    position: absolute;
    right: 0;
    top: 0;
  }
  ._1l83w3b0 ._1ett7rc0 > ._1cnty1i0 > * {
    width: 50%;
    text-align: left;
    min-height: 60px;
  }
}