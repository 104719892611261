._5incbs0 ._9izavu0 svg path {
  fill: currentColor;
}
._5incbs0 ._9izavu0 svg rect {
  fill: currentColor;
}
._5incbs0._9izavu1 {
  color: currentColor;
}
._5incbs1 {
  --_12k45md5: 28px;
  flex: 1;
  width: var(--_1ivttc5fq);
  background-color: var(--_6q1did15);
  color: var(--_6q1didy);
  display: flex;
  justify-content: center;
  position: relative;
}
._5incbs2 {
  width: var(--_1ivttc5dg);
  padding: var(--_1ivttc5cq);
  padding-top: var(--_1ivttc5g4);
  padding-bottom: var(--_1ivttc5g4);
}
._5incbs2 a {
  color: var(--_6q1did12);
}
._5incbs2 li {
  list-style: none;
}

  ._5incbs2 #cookie-settings-btn,
  ._5incbs2 #cookie-settings-btn:hover {
  padding: 0px;
  color: var(--_6q1did12);
  border: none;
  font-size: var(--_1ivttc5h7);
  background: var(--_6q1didu);
  appearance: none;
  cursor: pointer;
}
._5incbs3 {
  display: grid;
  row-gap: 16px;
  column-gap: 16px;
}
._5incbs3 h4 {
  margin-bottom: 16px;
}
._5incbs4 {
  padding-top: 24px;
  padding-bottom: 24px;
  padding-right: 2px;
  border-top: 1px solid var(--_6q1did12);
  border-bottom: 1px solid var(--_6q1did12);
}
._5incbs4 ul {
  margin-top: 20px;
}
._5incbs5 {
  display: flex;
  margin-top: var(--_1ivttc5g4);
  margin-bottom: var(--_1ivttc5g4);
}
._5incbs6 {
  width: 24px;
  margin-right: 8px;
  margin-left: 0;
}
._5incbs5 ul {
  display: flex;
  flex-direction: row;
  align-items: center;
}
._5incbs5 img {
  filter: invert(1);
}
._5incbs7 {
  background-color: var(--_6q1did12);
  height: 1px;
  width: 100%;
}
._5incbs8 {
  font-size: var(--_1ivttc5h7);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: var(--_1ivttc5g4);
}
._5incbs9 {
  flex: 1 1 50%;
  order: 1;
}
._5incbsa {
  display: flex;
  order: 0;
  width: 100%;
}
._5incbsa ul {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: var(--_1ivttc5g4);
}
._5incbsa li {
  display: inline-flex;
  align-content: center;
  flex: 1 1 50%;
  margin-bottom: 16px;
}
._5incbs8 img {
  width: 1em;
}
._5incbsb li {
  margin-bottom: 16px;
}
@media screen and (min-width:64em) {
  ._5incbs2 {
    padding: var(--_1ivttc5cr);
  }
  ._5incbs6 {
    margin-right: 0;
    margin-left: 8px;
  }
  ._5incbs5 ul {
    margin-left: auto;
  }
  ._5incbs8 {
    flex-wrap: nowrap;
  }
  ._5incbs9 {
    flex: unset;
  }
  ._5incbsa {
    order: 1;
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }
  ._5incbsa ul {
    margin-bottom: 0;
  }
  ._5incbsa li {
    flex: 1 1 auto;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 0;
  }
}