._1us4nwm0 {
  background: var(--_6q1did10);
  height: 4px;
  border-radius: 40px;
  margin: 24px auto 0;
  position: relative;
}
._1us4nwm1 {
  background: var(--_6q1did13);
  height: 4px;
  border-radius: 40px;
  position: absolute;
  left: 0px;
}