.jeulny0 {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
}
.jeulny1 {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-top: 20px;
}
.jeulny1 > * {
  flex: 1 1 100%;
  margin-bottom: 16px;
}
.jeulny1 > li {
  font-size: var(--_1ivttc5ha);
}
@media screen and (min-width:64em) {
  .jeulny0 {
    margin-top: 0;
  }
  .jeulny1 > * {
    flex: 1 1 50%;
  }
}