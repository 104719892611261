:root {
  --_6q1didu: transparent;
  --_6q1didv: transparent;
  --_6q1didw: #FFFFFF;
  --_6q1didx: #000000;
  --_6q1didy: #F5F5F5;
  --_6q1didz: #F4F4F4;
  --_6q1did10: #E0E0E0;
  --_6q1did11: #CCCCCC;
  --_6q1did12: #BFBFBF;
  --_6q1did13: #737373;
  --_6q1did14: #333333;
  --_6q1did15: #2E2E2E;
  --_6q1did16: #cee7b6;
  --_6q1did17: #c0fd00;
  --_6q1did18: #03c04a;
  --_6q1did19: #626457;
  --_6q1did1a: #465c35;
  --_6q1did1b: #1C5F4A;
  --_6q1did1c: #fec8a6;
  --_6q1did1d: #fb7522;
  --_6q1did1e: #65303d;
  --_6q1did1f: #d6c8ff;
  --_6q1did1g: #a76fff;
  --_6q1did1h: #1f355a;
  --_6q1did1i: #de8579;
  --_6q1did1j: #c59c6e;
  --_6q1did1k: #ec5770;
  --_6q1did1l: #1f30ca;
  --_6q1did1m: #B30911;
  --_6q1did1n: #D92F18;
}