.z5qsnh0 {
  display: inline-flex;
  align-self: flex-start;
  justify-self: flex-start;
  position: relative;
  background-color: transparent;
  color: var(--_6q1didx);
  z-index: 1;
  padding: var(--_1ivttc53b);
  opacity: 0.88;
  margin-bottom: var(--_1ivttc53a);
}
.z5qsnh0:before {
  content: "";
  background-color: var(--_6q1didw);
  border-radius: 10px;
  position: absolute;
  z-index: -1;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.z5qsnh2 {
  margin-left: var(--_1ivttc57);
}
.z5qsnh3 {
  margin-right: var(--_1ivttc56h);
}
.z5qsnh4 {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: var(--_1ivttc53f);
  height: var(--_1ivttc53f);
  bottom: var(--_1ivttc53a);
  padding: var(--_1ivttc55);
  opacity: 1;
  margin-bottom: var(--_1ivttc54);
}
.z5qsnh4:before {
  width: var(--_1ivttc53);
  height: var(--_1ivttc53);
  border-radius: 100%;
}
.z5qsnh2 .z5qsnh4 {
  left: var(--_1ivttc56l);
}
.z5qsnh3 .z5qsnh4 {
  right: var(--_1ivttc56l);
}
.z5qsnh4 > svg {
  width: var(--_1ivttc57);
}
.z5qsnh5 {
  opacity: 0.97;
}
.z5qsnh5:after {
  content: "";
  position: absolute;
  z-index: -1;
  bottom: 0;
  width: 0;
  height: 0;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  border-width: 22px;
  border-style: solid;
  border-color: var(--_6q1didw);
  border-top-color: var(--_6q1didw);
  border-bottom: 0;
  margin-bottom: -11px;
}
.z5qsnh6:after {
  left: 18px;
  border-bottom-right-radius: 100%;
  border-left: 0;
  margin-left: -10px;
  clip: rect(11px, auto, auto, 5px);
}
.z5qsnh7:after {
  right: 18px;
  border-bottom-left-radius: 100%;
  border-right: 0;
  margin-right: -10px;
  clip: rect(11px, 17px, auto, auto);
}
@media screen and (min-width:64em) {
  .z5qsnh0 {
    padding-left: var(--_1ivttc5dh);
    padding-right: var(--_1ivttc5dh);
  }
  .z5qsnh4 {
    padding: var(--_1ivttc55);
  }
}