._1g7ypy30 {
  background-color: var(--_6q1didx);
}
._1g7ypy31 {
  bottom: 0;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
._1g7ypy32 {
  align-items: center;
  background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,1));
  bottom: 0;
  display: flex;
  height: 80px;
  justify-content: space-between;
  left: 0;
  opacity: 0;
  padding: 0 20px;
  position: absolute;
  right: 0;
  transition: opacity 0.5s ease;
}
._1g7ypy31[data-show-controls] ._1g7ypy32 {
  opacity: 1;
}
._1g7ypy33 {
  appearance: none;
  background-color: transparent;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40'%3E%3Cpolygon points='15.6 12.6 15.6 27.4 28.4 20 15.6 12.6' fill='%23fff'/%3E%3Cpath d='M20,37A17,17,0,1,0,3,20,17,17,0,0,0,20,37Zm0,2A19,19,0,1,0,1,20,19,19,0,0,0,20,39Z' fill='%23fff' fill-rule='evenodd'/%3E%3C/svg%3E");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 24px;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 24px;
  width: 24px;
}
._1g7ypy33[data-is-playing=true] {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40'%3E%3Crect x='16.4' y='13' width='2.1' height='14.09' fill='%23fff'/%3E%3Cpath d='M20,37A17,17,0,1,0,3,20,17,17,0,0,0,20,37Zm0,2A19,19,0,1,0,1,20,19,19,0,0,0,20,39Z' fill='%23fff' fill-rule='evenodd'/%3E%3Crect x='21.5' y='13' width='2.1' height='14.09' fill='%23fff'/%3E%3C/svg%3E");
}
._1g7ypy34 {
  appearance: none;
  background-color: transparent;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40'%3E%3Cpath d='M17.7,11.7a.6.6,0,0,1,.9.5V27.4a.5.5,0,0,1-.8.4l-5.2-4H10.1a.5.5,0,0,1-.5-.5V16.7a.5.5,0,0,1,.5-.5h2.5Zm9.6.6a.5.5,0,0,1,.7,0,12,12,0,0,1,0,14.9.6.6,0,0,1-.8,0l-.7-.7a.5.5,0,0,1,0-.7,10.2,10.2,0,0,0,2.1-6.1,10.2,10.2,0,0,0-2.1-6c-.2-.2-.1-.5,0-.7Zm-4.6,3.1a.5.5,0,0,1,.8.1,7.4,7.4,0,0,1,1.4,4.3,7.8,7.8,0,0,1-1.4,4.3.5.5,0,0,1-.8,0l-.7-.7c-.1-.2-.2-.4,0-.6a5.8,5.8,0,0,0,.9-3,5.3,5.3,0,0,0-.9-3c-.2-.2-.1-.5,0-.7Z' fill='%23fff'/%3E%3Cpath d='M20,37A17,17,0,1,0,3,20,17,17,0,0,0,20,37Zm0,2A19,19,0,1,0,1,20,19,19,0,0,0,20,39Z' fill='%23fff' fill-rule='evenodd'/%3E%3C/svg%3E");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 40px;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  display: none;
  height: 44px;
  margin: auto 15px auto 0;
  width: 44px;
}
._1g7ypy34[data-is-muted=true] {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40'%3E%3Cpath d='M17.7,11.7a.6.6,0,0,1,.9.5V27.4a.5.5,0,0,1-.8.4l-5.2-4H10.1a.5.5,0,0,1-.5-.5V16.7a.5.5,0,0,1,.5-.5h2.5Zm9.6.6a.5.5,0,0,1,.7,0,12,12,0,0,1,0,14.9.6.6,0,0,1-.8,0l-.7-.7a.5.5,0,0,1,0-.7,10.2,10.2,0,0,0,2.1-6.1,10.2,10.2,0,0,0-2.1-6c-.2-.2-.1-.5,0-.7Zm-4.6,3.1a.5.5,0,0,1,.8.1,7.4,7.4,0,0,1,1.4,4.3,7.8,7.8,0,0,1-1.4,4.3.5.5,0,0,1-.8,0l-.7-.7c-.1-.2-.2-.4,0-.6a5.8,5.8,0,0,0,.9-3,5.3,5.3,0,0,0-.9-3c-.2-.2-.1-.5,0-.7Z' fill='%23fff'/%3E%3Cpath d='M20,1A19,19,0,1,0,39,20,19,19,0,0,0,20,1ZM3,20A16.9,16.9,0,0,1,7.3,8.7l24,24A17,17,0,0,1,3,20ZM32.7,31.3l-24-24a17,17,0,0,1,24,24Z' fill='%23fff'/%3E%3C/svg%3E");
}
._1g7ypy35 {
  appearance: none;
  background-color: transparent;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 90 90'%3E%3Cpolygon points='34.2 27.2 34.2 61.9 64.3 44.5 34.2 27.2' fill='%23fff'/%3E%3Cpath d='M45,89.5A44.5,44.5,0,1,1,89.5,45,44.6,44.6,0,0,1,45,89.5Zm0-87A42.5,42.5,0,1,0,87.5,45,42.6,42.6,0,0,0,45,2.5Z' fill='%23fff'/%3E%3C/svg%3E");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 60px 60px;
  border: 0;
  border-radius: 0;
  bottom: 0;
  cursor: pointer;
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.5s ease;
  width: 100%;
}
._1g7ypy35[data-is-playing=true] {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 90 90'%3E%3Crect x='36.5' y='28.5' width='5' height='33' fill='%23fff'/%3E%3Crect x='48.5' y='28.5' width='5' height='33' fill='%23fff'/%3E%3Cpath d='M45,89.5A44.5,44.5,0,1,1,89.5,45,44.6,44.6,0,0,1,45,89.5Zm0-87A42.5,42.5,0,1,0,87.5,45,42.6,42.6,0,0,0,45,2.5Z' fill='%23fff'/%3E%3C/svg%3E");
  opacity: 0;
}
._1g7ypy36 {
  flex: 1;
  height: 44px;
  margin: auto 25px;
  position: relative;
  width: auto;
}
._1g7ypy37 {
  bottom: 0;
  cursor: grab;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
._1g7ypy37:active {
  cursor: grabbing;
}
._1g7ypy38 {
  background-color: #fff;
  height: 2px;
  left: 0;
  opacity: 0.5;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}
._1g7ypy39 {
  background-color: #fff;
  height: 2px;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
._1g7ypy3a {
  background-color: #fff;
  height: 15px;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 6px;
}
._1g7ypy3b {
  color: #fff;
  font-family: monospace;
  font-size: var(--_1ivttc5h7);
  margin: auto 0;
  pointer-events: none;
  user-select: none;
}
._1g7ypy3c {
  display: none;
  height: 44px;
  margin: auto 0;
  position: relative;
  width: 100px;
}
._1g7ypy3d {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-end;
  position: sticky;
  bottom: 0;
  padding: 16px;
  left: 0;
  width: 100%;
  z-index: 5;
}
._1l83w3b0 ._1g7ypy3d {
  position: absolute;
}
@media screen and (min-width:64em) {
  ._1g7ypy31[data-show-controls] ._1g7ypy32 {
    opacity: 0;
  }
  ._1g7ypy31[data-show-controls]:hover ._1g7ypy32 {
    opacity: 1;
  }
  ._1g7ypy33 {
    background-size: 28px;
    height: 28px;
    width: 28px;
  }
  ._1g7ypy34 {
    display: block;
  }
  ._1g7ypy35 {
    background-size: 90px 90px;
    opacity: 1;
  }
  ._1g7ypy35[data-is-playing] {
    opacity: 0;
  }
  ._1g7ypy31:hover ._1g7ypy35[data-is-playing] {
    opacity: 1;
  }
  ._1g7ypy3c {
    display: block;
  }
  ._1g7ypy3d {
    padding: 24px;
  }
}