.sdvz5o0 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--_1ivttc5cq);
}
.sdvz5o1 {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px;
}
@media screen and (min-width:64em) {
  .sdvz5o1 {
    padding: 36px;
  }
}