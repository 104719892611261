.rcioba1 {
  cursor: pointer;
  display: flex;
  overflow: hidden;
  width: 100vw;
  position: relative;
}
.rcioba2 {
  align-items: flex-start;
  gap: 12px;
  padding-left: 3.7037037037037037vw;
  padding-right: 3.7037037037037037vw;
  padding-top: 12px;
  padding-bottom: 12px;
  min-height: 40px;
}
.rcioba3 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 3.7037037037037037vw;
}
.rcioba2 > *:last-child {
  margin-left: auto;
}
.rcioba2 .vvbgwx2 {
  position: static;
}
.rcioba2 ._1sg9d51d, .rcioba2 a:not(._964fy0) {
  color: var(--rcioba0);
  opacity: 1;
  max-width: none;
  line-height: normal;
}
.rcioba2 > ._1sg9d51d:first-of-type {
  max-width: 48.148148148148145vw;
  text-align: left;
}
@media screen and (min-width:64em) {
  .rcioba2 {
    align-items: center;
    gap: 0;
    justify-content: space-between;
  }
  .rcioba4 {
    justify-content: center;
  }
  .rcioba2 > *:last-child {
    margin-left: 0;
  }
  .rcioba2 > ._1sg9d51d:first-of-type {
    max-width: none;
  }
}