._19fduzq0 {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-style: solid;
  border-radius: var(--_1ivttc5e);
  text-align: center;
  border-width: var(--_1ivttc5i3);
  padding: var(--_1ivttc55) var(--_1ivttc57);
  font-weight: var(--_1ivttc5hn);
  line-height: var(--_1ivttc5hs);
  font-size: var(--_1ivttc5h8);
  transition: opacity 100ms ease-in;
  width: 100%;
}
._19fduzq0:hover {
  opacity: 0.7;
}
._19fduzq0:active {
  opacity: 0.5;
}
._19fduzq0:disabled {
  cursor: not-allowed;
  background-color: var(--_6q1did10);
  color: var(--_6q1did14);
  border-color: var(--_6q1did10);
}
._19fduzq0:hover:disabled {
  opacity: 1;
}
._19fduzq0:active:disabled {
  opacity: 1;
}
._19fduzq1 {
  background-color: var(--_6q1didx);
  color: var(--_6q1didw);
  border-color: var(--_6q1didw);
}
._19fduzq2 {
  background-color: var(--_6q1didv);
  color: currentColor;
  border-color: currentColor;
}
._19fduzq3 {
  background-color: var(--_6q1didv);
  color: currentColor;
  border-color: transparent;
}
._19fduzq4 {
  display: inline-flex;
  width: var(--_1ivttc52);
}
._19fduzq5 {
  font-size: var(--_1ivttc5hb);
  margin: 0 var(--_1ivttc56);
}
._19fduzq0 > * {
  margin-left: var(--_1ivttc55);
  margin-right: var(--_1ivttc55);
}
@media screen and (min-width:64em) {
  ._19fduzq0 {
    border-width: var(--_1ivttc5i4);
    padding: var(--_1ivttc56) var(--_1ivttc5a);
    font-weight: var(--_1ivttc5ho);
    font-size: var(--_1ivttc5h9);
  }
}