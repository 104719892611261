@keyframes _1sg1cwt0 {
  0% {
    transform: scale(.33);
    background-color: var(--_6q1didw);
  }
  80%, 100% {
    opacity: 0;
    background-color: var(--_6q1did15);
  }
}
._1sg1cwt1 {
  position: absolute;
  transform: translate(-50%, -50%);
  height: 21px;
  width: 21px;
}
._1sg1cwt2 {
  animation: _1sg1cwt0 1.5s cubic-bezier(0.20, 0.60, 0.35, 1) infinite;
  background-color: var(--_6q1didw);
  border: 2px solid var(--_6q1did10);
  border-radius: 50%;
  display: block;
  height: 200%;
  margin-left: -50%;
  margin-top: -50%;
  position: relative;
  width: 200%;
  will-change: transform, opacity, background-color;
}
._1sg1cwt3 {
  align-items: center;
  background-color: var(--_6q1did10);
  border-radius: 36px;
  cursor: pointer;
  display: flex;
  height: 36px;
  justify-content: center;
  left: 0;
  opacity: 0.8;
  position: absolute;
  top: 0;
  transition: opacity 0.2s ease, background-color 0.2s ease;
  width: 36px;
  z-index: 5;
}
._1sg1cwt3:hover {
  opacity: 1;
}
._1sg1cwt4 {
  height: 50%;
  width: 50%;
}
._1sg1cwt5 {
  background-color: rgba(255,255,255,.85);
  border: 1px solid #DEDEDE;
  border-radius: var(--_1ivttc55);
  bottom: 50%;
  height: auto;
  left: 50%;
  max-width: 300px;
  opacity: 0;
  padding: 34px 16px 20px;
  position: absolute;
  transition: opacity 0.2s ease 0.2s;
  visibility: hidden;
  width: max-content;
  z-index: 8;
}
._1sg1cwt6 {
  opacity: 1;
  visibility: visible;
}
._1sg1cwt7 {
  align-items: center;
  background: none, transparent;
  border: none;
  cursor: pointer;
  display: flex;
  font-size: 22px;
  height: 20px;
  justify-content: center;
  line-height: 1;
  opacity: 0.7;
  position: absolute;
  right: 8px;
  top: 8px;
  transition: opacity 0.2s ease;
  width: 20px;
  z-index: 5;
}
._1sg1cwt7:hover {
  opacity: 1;
}
._1sg1cwt8 {
  background: linear-gradient(to top left, transparent calc(50% - 1px), #DEDEDE , transparent calc(50% + 1px) );
  bottom: 50%;
  display: block;
  height: 120px;
  left: 50%;
  min-width: 1px;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  transform-origin: bottom left;
  transition: none;
  width: 80px;
}
._1sg1cwt9 {
  opacity: 1;
  transition: opacity 0.2s ease 0.2s;
}
._1sg1cwta {
  height: auto;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
._1sg1cwtb {
  align-items: center;
  background-color: var(--_6q1did10);
  border-radius: 36px;
  cursor: pointer;
  display: flex;
  height: 21px;
  justify-content: center;
  left: 50%;
  opacity: 0.8;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.2s ease, background-color 0.2s ease;
  width: 21px;
  z-index: 5;
}
._1sg1cwtb:before {
  content: "";
  background-color: rgba(255,255,255,0.9);
  border-radius: 21px;
  display: inline-block;
  height: 50%;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
}
._1sg1cwtc {
  background-color: rgba(255,255,255,.85);
  border: 1px solid #DEDEDE;
  border-radius: 1rem;
  max-width: 100vw;
  overflow: hidden;
  padding: 34px 16px 20px;
  position: relative;
  width: 100%;
}
@media screen and (min-width:48em) {
  ._1sg1cwt1 {
    height: 28px;
    width: 28px;
  }
}
@media screen and (min-width:64em) {
  ._1sg1cwt1 {
    height: 36px;
    width: 36px;
  }
}