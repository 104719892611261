._1ett7rc0._10l5unz0 {
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
}
._10l5unz1 {
  left: 0;
  width: 100%;
}
._10l5unz2 {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  justify-self: flex-start;
  width: var(--_1ivttc53);
  height: var(--_1ivttc53);
  z-index: 1;
}
._10l5unz2 > ._10l5unz3 {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
}
._10l5unz4 {
  margin-bottom: 16px;
}
._10l5unz1 ._10l5unz4 {
  align-self: center;
}