.dd6dj30 {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-top: 20px;
}
.dd6dj31 {
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
  padding: 12px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
}
.dd6dj30 > * {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 1 50%;
}
@media screen and (min-width:64em) {
  .dd6dj30 {
    flex-wrap: nowrap;
  }
  .dd6dj30 > * {
    flex: 1 1;
  }
}