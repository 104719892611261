._1fi66w90 {
  position: relative;
}
._1fi66w92 {
  position: relative;
  z-index: 10;
  height: 44px;
  width: 44px;
  padding-left: 5px;
}
._1fi66w93 {
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 40px;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  height: 36px;
  width: 36px;
  padding: 4px;
}
._1fi66w94 {
  height: 28px;
  width: 28px;
  padding: 8px;
  position: relative;
}
._1fi66w90 > video {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
._1fi66w91 > video {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
._1fi66w95 {
  pointer-events: none;
  font-size: 0;
}
._1l83w3b0 ._1fi66w95 {
  padding-bottom: 56px;
}
@media screen and (min-width:64em) {
  ._1l83w3b0 ._1fi66w95 {
    padding-bottom: 76px;
  }
}