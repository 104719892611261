._130vb490 {
  font-family: var(--_1ivttc50);
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--_1ivttc50);
}
html, body, #__next {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: var(--_1ivttc53);
}
html, body {
  font-size: var(--_1ivttc53);
  background-color: var(--_6q1didw);
  z-index: -1;
}
body {
  overflow-x: hidden;
  margin-right: calc(-1 * (100vw - 100%));
}
a {
  color: var(--_6q1didx);
  text-decoration: none;
}
._130vb491, ._130vb492, ._130vb492 * {
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  word-break: break-word;
  hyphens: auto;
  -webkit-hyphenate-before: 2;
  -webkit-hyphenate-after: 3;
  hyphenate-before: 2;
  hyphenate-after: 3;
  hyphenate-limit-chars: 6 2 3;
}
:lang(da) {
  -moz-hyphenate-resource: undefined;
  -webkit-hyphenate-resource: undefined;
  -ms-hyphenate-resource: undefined;
  hyphenate-resource: undefined;
}
:lang(sv) {
  -moz-hyphenate-resource: undefined;
  -webkit-hyphenate-resource: undefined;
  -ms-hyphenate-resource: undefined;
  hyphenate-resource: undefined;
}
:lang(no) {
  -moz-hyphenate-resource: undefined;
  -webkit-hyphenate-resource: undefined;
  -ms-hyphenate-resource: undefined;
  hyphenate-resource: undefined;
}
._130vb493, ._130vb494 img, ._130vb494 svg, ._130vb495, ._130vb495 * {
  user-drag: none;
  user-select: none;
  -drag: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
._130vb496 {
  display: none;
}
._130vb498 {
  display: flex;
}
._130vb49a {
  display: inline-flex;
}
._130vb49c {
  display: block;
}
._130vb49e {
  display: inline-block;
}
._130vb49g {
  display: grid;
}
._130vb49i {
  position: relative;
}
._130vb49k {
  position: absolute;
}
._130vb49m {
  position: static;
}
._130vb49o {
  border-style: solid;
}
._130vb49q {
  flex-direction: row;
}
._130vb49s {
  flex-direction: column;
}
._130vb49u {
  flex-direction: row-reverse;
}
._130vb49w {
  flex-direction: column-reverse;
}
._130vb49y {
  order: var(--_1ivttc5hx);
}
._130vb4910 {
  order: var(--_1ivttc5hy);
}
._130vb4912 {
  order: var(--_1ivttc5hz);
}
._130vb4914 {
  order: var(--_1ivttc5i0);
}
._130vb4916 {
  order: var(--_1ivttc5i1);
}
._130vb4918 {
  order: var(--_1ivttc5i2);
}
._130vb491a {
  align-self: stretch;
}
._130vb491c {
  align-self: flex-start;
}
._130vb491e {
  align-self: center;
}
._130vb491g {
  align-self: flex-end;
}
._130vb491i {
  align-self: baseline;
}
._130vb491k {
  align-items: stretch;
}
._130vb491m {
  align-items: flex-start;
}
._130vb491o {
  align-items: center;
}
._130vb491q {
  align-items: flex-end;
}
._130vb491s {
  align-items: baseline;
}
._130vb491u {
  justify-content: stretch;
}
._130vb491w {
  justify-content: flex-start;
}
._130vb491y {
  justify-content: center;
}
._130vb4920 {
  justify-content: flex-end;
}
._130vb4922 {
  justify-content: space-between;
}
._130vb4924 {
  text-align: left;
}
._130vb4926 {
  text-align: center;
}
._130vb4928 {
  text-align: right;
}
._130vb492a {
  word-wrap: normal;
}
._130vb492c {
  word-wrap: break-word;
}
._130vb492e {
  cursor: pointer;
}
._130vb492g {
  cursor: grab;
}
._130vb492i {
  cursor: default;
}
._130vb492k {
  cursor: auto;
}
._130vb492m {
  white-space: pre;
}
._130vb492o {
  white-space: pre-line;
}
._130vb492q {
  white-space: normal;
}
._130vb492s {
  white-space: nowrap;
}
a[href]:focus, button:focus, input:focus, select:focus, textarea:focus, iframe:focus {
  outline: thin dotted;
}
a[href]:focus:not(:focus-visible), button:focus:not(:focus-visible), input:focus:not(:focus-visible), select:focus:not(:focus-visible), textarea:focus:not(:focus-visible), iframe:focus:not(:focus-visible) {
  outline: none;
}
a[href]:focus-visible, button:focus-visible, input:focus-visible, select:focus-visible, textarea:focus-visible, iframe:focus-visible {
  outline: thin dotted;
}
@media screen and (min-width:90em) {
  html, body {
    --_1ivttc5cp: 0px;
    --_1ivttc5cq: 53.333333333333336px;
    --_1ivttc5cr: 106.66666666666667px;
    --_1ivttc5cs: 160px;
    --_1ivttc5ct: 213.33333333333334px;
    --_1ivttc5cu: 266.6666666666667px;
    --_1ivttc5cv: 320px;
    --_1ivttc5cw: 373.3333333333333px;
    --_1ivttc5cx: 426.6666666666667px;
    --_1ivttc5cy: 480px;
    --_1ivttc5cz: 533.3333333333334px;
    --_1ivttc5d0: 586.6666666666666px;
    --_1ivttc5d1: 640px;
    --_1ivttc5d2: 693.3333333333334px;
    --_1ivttc5d3: 746.6666666666666px;
    --_1ivttc5d4: 800px;
    --_1ivttc5d5: 853.3333333333334px;
    --_1ivttc5d6: 906.6666666666666px;
    --_1ivttc5d7: 960px;
    --_1ivttc5d8: 1013.3333333333334px;
    --_1ivttc5d9: 1066.6666666666667px;
    --_1ivttc5da: 1120px;
    --_1ivttc5db: 1173.3333333333333px;
    --_1ivttc5dc: 1226.6666666666667px;
    --_1ivttc5dd: 1280px;
    --_1ivttc5de: 1333.3333333333333px;
    --_1ivttc5df: 1386.6666666666667px;
    --_1ivttc5dg: 1440px;
    --_1ivttc5dh: 26.666666666666668px;
    --_1ivttc5di: 80px;
    --_1ivttc5dj: 133.33333333333334px;
    --_1ivttc5dk: 186.66666666666666px;
    --_1ivttc5dl: 240px;
    --_1ivttc5dm: 293.3333333333333px;
    --_1ivttc5dn: 346.6666666666667px;
    --_1ivttc5do: 400px;
    --_1ivttc5dp: 453.3333333333333px;
    --_1ivttc5dq: 506.6666666666667px;
    --_1ivttc5dr: 560px;
    --_1ivttc5ds: 613.3333333333334px;
    --_1ivttc5dt: 666.6666666666666px;
    --_1ivttc5du: 720px;
    --_1ivttc5dv: 773.3333333333334px;
    --_1ivttc5dw: 826.6666666666666px;
    --_1ivttc5dx: 880px;
    --_1ivttc5dy: 933.3333333333334px;
    --_1ivttc5dz: 986.6666666666666px;
    --_1ivttc5e0: 1040px;
    --_1ivttc5e1: 1093.3333333333333px;
    --_1ivttc5e2: 1146.6666666666667px;
    --_1ivttc5e3: 1200px;
    --_1ivttc5e4: 1253.3333333333333px;
    --_1ivttc5e5: 1306.6666666666667px;
    --_1ivttc5e6: 1360px;
    --_1ivttc5e7: 1413.3333333333333px;
    --_1ivttc5e8: -53.333333333333336px;
    --_1ivttc5e9: -106.66666666666667px;
    --_1ivttc5ea: -160px;
    --_1ivttc5eb: -213.33333333333334px;
    --_1ivttc5ec: -266.6666666666667px;
    --_1ivttc5ed: -320px;
    --_1ivttc5ee: -373.3333333333333px;
    --_1ivttc5ef: -426.6666666666667px;
    --_1ivttc5eg: -480px;
    --_1ivttc5eh: -533.3333333333334px;
    --_1ivttc5ei: -586.6666666666666px;
    --_1ivttc5ej: -640px;
    --_1ivttc5ek: -693.3333333333334px;
    --_1ivttc5el: -746.6666666666666px;
    --_1ivttc5em: -800px;
    --_1ivttc5en: -853.3333333333334px;
    --_1ivttc5eo: -906.6666666666666px;
    --_1ivttc5ep: -960px;
    --_1ivttc5eq: -1013.3333333333334px;
    --_1ivttc5er: -1066.6666666666667px;
    --_1ivttc5es: -1120px;
    --_1ivttc5et: -1173.3333333333333px;
    --_1ivttc5eu: -1226.6666666666667px;
    --_1ivttc5ev: -1280px;
    --_1ivttc5ew: -1333.3333333333333px;
    --_1ivttc5ex: -1386.6666666666667px;
    --_1ivttc5ey: -1440px;
    --_1ivttc5ez: -26.666666666666668px;
    --_1ivttc5f0: -80px;
    --_1ivttc5f1: -133.33333333333334px;
    --_1ivttc5f2: -186.66666666666666px;
    --_1ivttc5f3: -240px;
    --_1ivttc5f4: -293.3333333333333px;
    --_1ivttc5f5: -346.6666666666667px;
    --_1ivttc5f6: -400px;
    --_1ivttc5f7: -453.3333333333333px;
    --_1ivttc5f8: -506.6666666666667px;
    --_1ivttc5f9: -560px;
    --_1ivttc5fa: -613.3333333333334px;
    --_1ivttc5fb: -666.6666666666666px;
    --_1ivttc5fc: -720px;
    --_1ivttc5fd: -773.3333333333334px;
    --_1ivttc5fe: -826.6666666666666px;
    --_1ivttc5ff: -880px;
    --_1ivttc5fg: -933.3333333333334px;
    --_1ivttc5fh: -986.6666666666666px;
    --_1ivttc5fi: -1040px;
    --_1ivttc5fj: -1093.3333333333333px;
    --_1ivttc5fk: -1146.6666666666667px;
    --_1ivttc5fl: -1200px;
    --_1ivttc5fm: -1253.3333333333333px;
    --_1ivttc5fn: -1306.6666666666667px;
    --_1ivttc5fo: -1360px;
    --_1ivttc5fp: -1413.3333333333333px;
    --_1ivttc5fq: 100vw;
  }
}
@supports (width:-moz-fit-content) {
  html, body {
    --_1ivttc51: auto;
    --_1ivttc52: -moz-fit-content;
  }
}
@media screen and (min-width:64em) {
  ._130vb497 {
    display: none;
  }
  ._130vb499 {
    display: flex;
  }
  ._130vb49b {
    display: inline-flex;
  }
  ._130vb49d {
    display: block;
  }
  ._130vb49f {
    display: inline-block;
  }
  ._130vb49h {
    display: grid;
  }
  ._130vb49j {
    position: relative;
  }
  ._130vb49l {
    position: absolute;
  }
  ._130vb49n {
    position: static;
  }
  ._130vb49p {
    border-style: solid;
  }
  ._130vb49r {
    flex-direction: row;
  }
  ._130vb49t {
    flex-direction: column;
  }
  ._130vb49v {
    flex-direction: row-reverse;
  }
  ._130vb49x {
    flex-direction: column-reverse;
  }
  ._130vb49z {
    order: var(--_1ivttc5hx);
  }
  ._130vb4911 {
    order: var(--_1ivttc5hy);
  }
  ._130vb4913 {
    order: var(--_1ivttc5hz);
  }
  ._130vb4915 {
    order: var(--_1ivttc5i0);
  }
  ._130vb4917 {
    order: var(--_1ivttc5i1);
  }
  ._130vb4919 {
    order: var(--_1ivttc5i2);
  }
  ._130vb491b {
    align-self: stretch;
  }
  ._130vb491d {
    align-self: flex-start;
  }
  ._130vb491f {
    align-self: center;
  }
  ._130vb491h {
    align-self: flex-end;
  }
  ._130vb491j {
    align-self: baseline;
  }
  ._130vb491l {
    align-items: stretch;
  }
  ._130vb491n {
    align-items: flex-start;
  }
  ._130vb491p {
    align-items: center;
  }
  ._130vb491r {
    align-items: flex-end;
  }
  ._130vb491t {
    align-items: baseline;
  }
  ._130vb491v {
    justify-content: stretch;
  }
  ._130vb491x {
    justify-content: flex-start;
  }
  ._130vb491z {
    justify-content: center;
  }
  ._130vb4921 {
    justify-content: flex-end;
  }
  ._130vb4923 {
    justify-content: space-between;
  }
  ._130vb4925 {
    text-align: left;
  }
  ._130vb4927 {
    text-align: center;
  }
  ._130vb4929 {
    text-align: right;
  }
  ._130vb492b {
    word-wrap: normal;
  }
  ._130vb492d {
    word-wrap: break-word;
  }
  ._130vb492f {
    cursor: pointer;
  }
  ._130vb492h {
    cursor: grab;
  }
  ._130vb492j {
    cursor: default;
  }
  ._130vb492l {
    cursor: auto;
  }
  ._130vb492n {
    white-space: pre;
  }
  ._130vb492p {
    white-space: pre-line;
  }
  ._130vb492r {
    white-space: normal;
  }
  ._130vb492t {
    white-space: nowrap;
  }
}